import React, { useEffect } from 'react';
import Solution from './Solution';
// import AvailableOn from './AvailableOn'
import Features from './Features'
import Clients from './Clients'
import CheckGallery from './CheckGallery'
import Upcoming from './Upcoming'
import Faq from './Faq'
import HeroSection from './HeroSection'
import Navbar from '../Navbar';
import Footer from '../Footer';

const HomePage = () => {
    useEffect(() => {
        document.title = 'Owniverse - Own your metaverse';
        window.scrollTo(0, 0);
    }, []);
    return (
        <div >
            <Navbar />
            <HeroSection />
            <Solution />
            {/* <AvailableOn /> */}
            <Features />
            <Clients />
            <CheckGallery />
            <Upcoming />
            <Faq />
            <Footer />
        </div>
    );
};

export default HomePage;


import './MetaTalan.css'
export default function MetaWhite() {

    return (
        <>
            <svg className="meta-white" viewBox="0 0 1370 300" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_456_6353)" filter="url(#filter0_f_456_6353)">
                    <path d="M43.5714 132.318C54.9585 117.196 69.4085 108.597 88.6214 108.918C107.351 109.182 122.548 115.719 132.909 132.231C141.556 120.531 152.254 112.019 166.616 109.869C200.778 104.735 227.025 121.569 228.916 157.254C230.572 188.478 229.458 219.864 229.634 251.176C229.634 256.266 228.095 257.655 222.746 256.353C205.482 252.171 195.282 239.535 195.282 221.253C195.282 203.498 196.044 185.729 194.696 167.989C193.787 155.909 188.834 145.847 176.655 141.912C164.477 137.978 152.445 138.914 142.333 148.289C141.197 149.154 140.353 150.344 139.913 151.7C139.473 153.056 139.458 154.514 139.871 155.879C140.387 158.108 140.618 160.393 140.559 162.68C140.559 192.471 140.486 222.262 140.706 252.054C140.706 256.309 139.753 257.538 135.415 257.333C127.179 256.953 118.914 257.026 110.677 257.333C106.955 257.465 105.929 256.456 105.944 252.668C106.134 226.504 105.944 200.339 106.076 174.19C106.192 169.605 105.614 165.03 104.361 160.618C99.6568 145.13 88.0645 138.329 69.9214 140.318C56.3507 141.781 45.0076 153.759 43.9378 168.106C42.0179 195.177 43.5274 222.306 43.1024 249.406C42.9265 256.88 43.1024 256.88 35.2179 256.88C27.8903 256.88 20.6506 256.763 13.367 256.88C10.1722 256.968 8.97046 255.988 8.97046 252.668C9.05839 207.028 9.05839 161.388 8.97046 115.748C8.97046 112.94 9.68856 111.785 12.8248 111.609C30.1179 110.629 38.1929 115.821 43.5714 132.318Z" fill="white" fillOpacity="0.39" />
                    <path d="M726.035 174.863C726.035 211.162 726.035 247.456 726.035 283.746C726.035 291.702 726.034 291.702 718.399 290.269C702.674 287.344 691.624 273.742 691.624 257.07C691.565 193.461 691.565 129.852 691.624 66.2424C691.624 58.7251 691.624 58.7251 683.798 58.7251C661.933 58.7251 640.067 58.5935 618.202 58.8129C613.043 58.8129 612.12 57.3504 613.16 52.5095C616.165 38.3963 628.299 27.6469 642.808 27.6469C695.361 27.5153 747.915 27.6469 800.468 27.5153C804.205 27.5153 804.865 28.5829 804.63 32.0637C803.443 45.7235 790.62 58.2279 776.639 58.5057C761.676 58.8129 746.713 58.9152 731.75 58.5057C726.987 58.3887 725.976 59.8366 726.005 64.1949C726.034 101.123 726.035 137.993 726.035 174.863Z" fill="white" fillOpacity="0.39" />
                    <path d="M331.443 199.842C318.591 199.842 305.738 200.105 292.9 199.71C287.624 199.55 287.566 201.378 288.504 205.561C290.775 216.471 296.608 222.218 312.406 224.88C329.465 227.805 345.893 224.398 361.809 218.211C370.602 214.804 371.027 214.76 371.54 224.061C372.258 236.99 365.854 252.946 349.088 256.924C327.369 262.072 305.46 262.949 284.078 254.964C263.004 247.081 251.587 231.11 247.674 209.539C244.362 191.228 244.875 173.049 252.071 155.631C268.631 115.748 312.67 106.33 344.179 121.54C362.41 130.315 370.778 146.402 374.222 165.634C376.025 175.682 375.013 185.831 375.453 195.923C375.614 199.433 374.193 199.871 371.174 199.842C357.911 199.681 344.677 199.842 331.443 199.842ZM311.981 173.21C318.884 173.21 325.772 173.21 332.66 173.21C334.023 173.21 335.591 173.839 336.236 171.513C338.991 161.422 329.406 146.534 318.884 144.793C303.716 142.293 288.533 154.899 287.815 170.402C287.683 173.488 289.28 173.254 291.318 173.254C298.205 173.122 305.093 173.137 311.981 173.137V173.21Z" fill="white" fillOpacity="0.39" />
                    <path d="M543.988 148.201C538.199 135.492 544.97 114.637 565.575 110.951C581.872 108.026 598.373 107.5 614.626 111.668C637.297 117.518 652.334 135.287 653.843 158.951C655.822 189.853 654.253 220.829 654.708 251.761C654.708 254.935 653.579 256.324 650.311 255.973C648.396 255.783 646.467 255.783 644.552 255.973C632.285 257.07 623.595 252.741 620.312 239.885C602.565 257.816 581.388 262.423 557.72 257.143C535.59 252.2 521.902 235.747 521.565 214.394C521.214 191.945 534.506 174.746 556.84 168.925C576.771 163.733 596.629 165.488 616.253 170.46C621.807 171.923 621.235 169.305 620.649 165.985C617.718 147.879 607.459 139.748 586.796 139.177C573.767 138.826 561.369 141.781 549.234 146.197C547.505 146.812 545.776 147.528 543.988 148.201ZM589.741 190.102C582.296 190.38 574.881 190.482 567.934 193.904C559.727 197.955 555.404 204.142 555.903 213.443C556.386 222.218 561.515 227.527 569.356 230.803C575.965 233.582 582.868 233.29 589.697 232.895C609.54 231.739 622.159 218.05 621.558 199.067C621.655 197.972 621.337 196.881 620.667 196.009C619.997 195.137 619.023 194.548 617.938 194.358C608.808 191.529 599.301 190.094 589.741 190.102Z" fill="white" fillOpacity="0.39" />
                    <path d="M1068.66 237.399C1053.89 253.033 1035.49 257.743 1014.76 255.768C992.275 253.633 974.249 237.648 972.535 218.08C970.19 191.433 982.793 173.693 1009.04 167.477C1027.48 163.089 1045.88 164.625 1064.03 169.29C1068.42 170.431 1069.54 169.685 1069.3 165.137C1069.02 159.568 1066.97 154.233 1063.45 149.901C1059.93 145.57 1055.12 142.465 1049.72 141.035C1033.16 136.267 1017.16 139.704 1001.36 144.881C1000.44 145.138 999.553 145.491 998.709 145.934C993.917 148.859 992.847 145.861 992.275 141.942C991.323 135.437 992.809 128.811 996.449 123.333C1000.09 117.854 1005.63 113.909 1012 112.253C1031.42 107.456 1051.07 107.149 1070.12 114.447C1089.18 121.744 1098.63 136.311 1101.35 155.572C1103.73 172.245 1103.36 189.21 1102.1 205.882C1101.03 220.01 1103 233.889 1102.58 247.885C1102.47 252.024 1101.52 253.984 1097.1 253.487C1094.83 253.223 1092.5 253.487 1090.2 253.487C1077.55 253.092 1072.82 249.567 1068.66 237.399ZM1034.09 230.906C1055.94 231.037 1069.17 219.367 1069.8 198.95C1069.92 195.323 1069.17 193.246 1065.24 192.603C1060.85 191.901 1056.73 190.321 1052.41 189.795C1041.06 188.405 1029.63 187.484 1018.7 191.945C1009.63 195.63 1005.29 202.314 1005.61 211.411C1005.89 219.747 1011.18 226.138 1020.27 229.092C1024.72 230.567 1029.41 231.182 1034.09 230.906Z" fill="white" fillOpacity="0.39" />
                    <path d="M848.039 237.502C838.821 246.759 828.518 253.121 815.988 255.052C801.333 257.319 786.942 256.719 773.576 249.289C758.276 240.792 751.051 227.483 751.608 210.08C752.165 192.676 759.8 179.733 775.554 172.055C790.693 164.61 806.917 164.23 823.286 165.693C830.545 166.218 837.722 167.552 844.683 169.671C847.79 170.68 848.508 169.671 848.479 166.526C848.332 152.545 839.305 142.439 824.869 139.938C808.441 137.101 792.877 140.333 777.709 146.212C773.928 147.675 772.609 147.572 771.847 143.155C770.595 136.108 772.153 128.852 776.189 122.936C780.226 117.02 786.419 112.913 793.448 111.492C810.097 108.275 826.833 107.704 843.496 112.37C864.013 118.147 877.584 133.43 880.5 154.388C882.757 170.548 881.262 186.782 881.555 202.987C881.819 218.255 881.556 233.538 881.687 248.807C881.687 252.097 881.057 253.809 877.291 253.501C873.524 253.194 869.963 253.589 866.373 253.399C856.466 252.858 850.794 247.154 848.039 237.502ZM811.65 230.774C835.714 231.023 849.93 217.948 849.358 197.59C849.481 196.491 849.175 195.387 848.504 194.507C847.832 193.627 846.847 193.04 845.753 192.866C836.788 190.076 827.445 188.684 818.055 188.742C810.009 188.873 801.934 189.21 794.753 193.597C788.114 197.619 784.641 203.367 784.949 211.147C784.985 214.609 786.017 217.987 787.921 220.88C789.825 223.773 792.522 226.06 795.691 227.469C800.639 229.917 806.135 231.055 811.65 230.774Z" fill="white" fillOpacity="0.39" />
                    <path d="M390.431 129.642C390.431 105.759 390.533 81.8912 390.328 57.9793C390.328 53.8989 391.369 52.7289 395.457 52.9336C414.245 53.8404 425.838 65.7744 425.808 84.4359C425.808 95.1415 426.219 105.847 425.808 116.523C425.618 121.437 427.567 121.905 431.67 121.861C449.696 121.686 467.722 121.861 485.763 121.701C490.159 121.701 491.889 122.198 490.907 127.346C488.152 141.868 478.684 150.746 463.853 151.258C453.126 151.609 442.354 151.711 431.612 151.258C426.57 151.068 425.75 152.72 425.838 157.283C426.175 170.446 425.984 183.608 426.014 196.771C426.014 218.036 438.514 230.92 460.028 231.681C474.423 232.085 488.711 229.105 501.737 222.979C507.599 220.317 508.156 220.814 508.933 227.366C511.263 247.096 498.439 263.52 478.303 265.392C459.896 267.088 441.87 268.17 424.475 260.536C407.665 253.224 398.257 239.915 393.699 222.584C388.965 204.376 390.504 185.802 390.255 167.331C390.094 154.724 390.255 142.117 390.255 129.51L390.431 129.642Z" fill="white" fillOpacity="0.39" />
                    <path d="M1168.8 131.178C1177 120.794 1186.54 112.487 1199.85 109.854C1230.63 103.756 1258.78 118.059 1262.77 152.852C1263.41 158.347 1263.74 163.875 1263.75 169.407C1263.75 195.187 1263.75 220.975 1263.75 246.774C1263.75 253.677 1263.21 254.086 1256.57 252.478C1239.63 248.339 1230.19 236.58 1230.03 219.293C1229.89 201.743 1230.77 184.193 1229.44 166.643C1228.21 150.38 1218.21 139.719 1203.6 138.856C1185.21 137.803 1172.45 147.163 1169.3 163.894C1168.56 167.834 1168.19 171.834 1168.2 175.842C1168.2 199.901 1168.12 223.973 1168.31 248.046C1168.31 252.244 1167.35 253.765 1162.91 253.56C1155.07 253.209 1147.18 253.326 1139.33 253.56C1135.82 253.648 1134.74 252.492 1134.75 248.968C1134.86 204.459 1134.86 159.95 1134.75 115.441C1134.75 112.136 1135.85 111.331 1139.05 111.273C1153.73 110.98 1162.64 113.101 1168.8 131.178Z" fill="white" fillOpacity="0.39" />
                    <path d="M1340.72 100.743C1318.27 100.494 1323.69 98.0665 1316.76 116.655C1313.56 125.225 1310.78 133.942 1307.96 142.629C1306.72 146.388 1304.84 149.254 1300.4 149.137C1295.58 149.02 1293.95 145.744 1293.18 141.678C1290.24 126.732 1287.12 111.829 1284.68 96.8234C1283.8 91.4414 1281.54 89.9496 1276.44 89.9789C1262.82 90.0812 1249.22 89.6863 1235.61 89.584C1232.56 89.584 1229.75 89.2476 1228.4 86.1032C1226.93 82.6956 1228.88 80.341 1231.33 78.1764C1239.8 70.7323 1247.99 62.9518 1256.74 55.8294C1261.59 51.8806 1262.21 47.8441 1260.33 42.3305C1257.93 35.3105 1256.13 28.1149 1254.08 20.9925C1252.99 17.2778 1251.72 13.4314 1255.54 10.5064C1259.37 7.58137 1263.15 9.61426 1266.66 11.6325C1275.77 16.8683 1284.88 22.0894 1293.89 27.4714C1295.55 28.5826 1297.44 29.2989 1299.42 29.5652C1301.4 29.8314 1303.42 29.6405 1305.31 29.0071C1313.88 26.3453 1322.59 24.1223 1331.29 21.8993C1338.24 20.115 1341.45 23.2009 1340.53 30.3379C1339.54 37.8991 1338.71 45.4748 1337.71 53.036C1337.2 56.9263 1338.77 60.0853 1340.83 63.1565C1346.62 71.6878 1352.39 80.224 1358.16 88.765C1359.97 91.456 1362.23 94.1762 1360.36 97.6424C1358.48 101.108 1354.92 100.684 1351.68 100.728C1347.99 100.787 1344.35 100.743 1340.72 100.743Z" fill="white" fillOpacity="0.39" />
                    <path d="M947.621 169.495C947.621 195.63 947.533 221.78 947.709 247.915C947.709 252.522 946.786 253.955 941.847 252.96C924.422 249.406 914.119 237.004 914.002 218.328C913.87 187.031 914.002 155.733 914.002 124.435C914.002 104.019 914.002 83.6023 914.002 63.1858C914.002 57.2188 914.925 56.5606 920.817 57.8623C937.626 61.6063 947.68 74.5494 947.724 92.7722C947.797 118.351 947.724 143.96 947.724 169.495H947.621Z" fill="white" fillOpacity="0.39" />
                </g>
                <defs>
                    <filter id="filter0_f_456_6353" x="0" y="0" width="1370" height="300" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="4.5" result="effect1_foregroundBlur_456_6353" />
                    </filter>
                    <clipPath id="clip0_456_6353">
                        <rect width="1352" height="282" fill="white" transform="translate(9 9)" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}
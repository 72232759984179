import React, { useEffect } from 'react';
import './ContactUsPage.css'
import Form from './Form';
import ContactUs from './Contact';
import Navbar from '../Navbar';
import Footer from '../Footer';

const ContactUsPage = () => {
    useEffect(() => {
        document.title = 'Owniverse - Contact us';
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <section className="contact">
                <Navbar />
                <div className="container">

                    <div className="section-title" data-aos="fade-up">
                        <h2>Get in touch</h2>
                        <p>Contact Us</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4" data-aos="fade-right" data-aos-delay="100">
                            <ContactUs />
                        </div>
                        <div className="col-lg-6 mt-5 mt-lg-0 offset-1" data-aos="fade-left" data-aos-delay="200">
                            <Form />
                        </div>

                    </div>

                </div>
            </section>
            <Footer />
        </div>

    );
};

export default ContactUsPage;
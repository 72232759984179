import React from 'react'
import './ConnectButton.css'

export default function ConnectButton({ handleContinueClicked }) {
    return (
        <div className="c-box" onClick={handleContinueClicked}>
            <div className="c-rectangle">
                <div className='connect'>Connect</div>
            </div>
        </div>
    )
}

import React from 'react'
import './Loader.css'

export default function Loader({ loadingPercentage }) {


    const padding =
        loadingPercentage === 90 ? '5px 296px' : `5px ${5 + loadingPercentage * 2.95}px `;

    return (
        <>
            <div className="label">
                {Math.round(loadingPercentage) !== 90 ?
                    (<div className="percentage">{Math.round(loadingPercentage)}%</div>) :
                    <div className="percentage">Loading complete</div>
                }

            </div>
            <div className="loading-bar">
                <button className="button" style={{ padding }}>
                </button>
            </div>
        </>
    )
}

import './MetaTalan.css'
import metatalan from "../../assets/img/metatalan/logo-metatalan.svg"
import star from "../../assets/img/metatalan/vector.svg"
import star2 from "../../assets/img/metatalan/vector-2.svg"
import star7 from "../../assets/img/metatalan/vector-2.svg"
import polygon from "../../assets/img/metatalan/polygon-1.svg"
import MetaWhite from './MetaWhite'
import React from "react";
// import Vector from './Vector'
import { useEffect, useState } from "react";
import VrBox from '../VrBox/VrBox'
import BrowserBox from '../BrowserBox/BrowserBox'


export default function MetaTalan({ handleLogin, vrCode, handlePlatformChange }) {

    const [code, setCode] = useState(null)
    const [platform, setPlatform] = useState(null)


    const handleVRfocus = () => {
        setPlatform("VR")
    }
    const handleBrowserfocus = () => {
        setPlatform("browser")
    }

    const handleUnfocus = () => {
        setPlatform(null)

    }

    useEffect(() => {
        setCode(vrCode)
        if (vrCode !== null) { setPlatform("VR") }
    }, [vrCode])

    useEffect(() => {
        handlePlatformChange(platform)
    }, [platform])

    return (
        <div className="HERO-PAGE">
            <div className="frame-wrapper">
                <div className="floating-elements">
                    <MetaWhite />
                    <div className="ellipse-2 float-v" />
                    <div className="ellipse-3 float-h" />
                    <div className="ellipse-4 float-v" />
                    <div className="ellipse-5 float-v" />
                    <img className="vector float-v" alt="Vector" src={star} />
                    <img className="vector-7 float-h" alt="Vector" src={star7} />
                    <img className="vector-2 float-v" alt="Vector" src={star2} />
                    <img className="polygon float-h" alt="Vector" src={polygon} />
                </div>
                <img className="metatalan-logo" src={metatalan} alt="metatalan-logo" />
                <p className='moto'>The ultimate solution to ensure the best onboarding process In <b>VR</b>  or <b>WEB</b></p>
                <div className='cards'>
                    <div style={{ visibility: platform === 'browser' && window.innerWidth > 1367 ? 'hidden' : 'visible' }}>
                        <VrBox focus={handleVRfocus} unfocus={handleUnfocus} handleLogin={handleLogin} vrCode={vrCode} />
                    </div>
                    <div style={{ visibility: platform === 'VR' && window.innerWidth > 1367 ? 'hidden' : 'visible' }}>
                        <BrowserBox focus={handleBrowserfocus} unfocus={handleUnfocus} handleLogin={handleLogin} />
                    </div>
                </div>
            </div>
        </div>

    )
}
import React from 'react';
import Contact from '../Contact';

const ContactUs = () => {
    return (
        <div className="info">
            <Contact
                title="Call:"
                description="+216 70 015 065"
                icon="bi bi-phone"
            />
            <Contact
                title="Email:"
                description="support@owniverse.org"
                icon="bi bi-envelope"
            />
            <Contact
                title="Location:"
                description="10 Rue de l'énergie solaire Impasse N°1 Charguia 1، Tunis 2035"
                icon="bi bi-geo-alt"
            />

        </div>
    );
};

export default ContactUs;
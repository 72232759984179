import React, { useEffect } from 'react';
import ArticleCard from './ArticleCard'
import blockchain from "../../assets/img/blog/blockchain-and-the-metaverse.webp"
import aiMetaverse from "../../assets/img/blog/aiMetaverse.png"
import metaverse from "../../assets/img/blog/metaverse-c.jpg"
import owniverse_view from "../../assets/img/blog/owniverse_view.webp"
import Navbar from '../Navbar';
import Footer from '../Footer';
import HeroMain from '../HeroMain';
import {
    MDBRow,
} from 'mdb-react-ui-kit';

const SolutionPage = () => {
    useEffect(() => {
        document.title = 'Owniverse - Blog';
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Navbar />
            <HeroMain
                title="The Owniverse Blog"
                description="Learn more about the metaverse and expand your knowledge in regards of our virtual future."
                buttonText="Expand your knowldge"
                buttonHref="#blog"
            />
            <section>
                <div id='blog' className="container">
                    <div className="section-title" data-aos="fade-up">
                        <h2>Check our blog</h2>
                        <p>Blog</p>
                    </div>
                    <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
                        <ArticleCard
                            title="Owniverse - Own your metaverse"
                            description="When we first started to design the metaverse, we never dreamed of coming this far. At first, we only thought of experimenting and riding the wave of trendiness. As we evolved and built MetaTalan (Talan’s meta workplace), our
                            perception of things changed."
                            image={owniverse_view}
                            link="/owniverse-own-your-metaverse"
                            tag="Digital human - NFT"
                        />
                        <ArticleCard
                            title="AI and the Metaverse"
                            description="Nowadays, Metaverse is a hot topic that has moved from the buzzword territory to real
                            world app. In metaverse experience, users are present in the digital content rather than observing it
                            from the outside."
                            image={aiMetaverse}
                            link="/ai-and-the-metaverse"
                            tag="AI"
                        />

                        <ArticleCard
                            title="The metaverse, an upgrade not a trend"
                            description="Metaverse! The booming concept echoing across the globe, the revolution of the
                            decade, the hype of the 21st century, the future of the Internet, the demise of traditional technology."
                            image={metaverse}
                            link="/the-metaverse-an-upgrade-not-a-trend"
                            tag="fundamentals"
                        />
                        <ArticleCard
                            title="Blockchain in the Metaverse"
                            description="The Metaverse has been capturing a lot of public attention ever since Facebook’s
                            rebranding to Meta. But this concept is not new to us."
                            image={blockchain}
                            link="/blockchain-in-the-metaverse"
                            tag="Blockchain"
                        />
                    </MDBRow>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default SolutionPage;
import React, { useEffect } from 'react';
import Gallery from './Gallery';
import Footer from '../Footer';
import HeroMain from '../HeroMain';
const SolutionPage = () => {
    useEffect(() => {
        document.title = 'Owniverse - Gallery';
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>

            <HeroMain
                title="Owniverse Gallery"
                description="Feel the experience of the metaverse and join the journey."
                buttonText="Explore more"
                buttonHref="#gallery"
            />
            <Gallery />
            <Footer />
        </div>
    );
};

export default SolutionPage;
import React, { useEffect, useRef } from 'react';
import './CheckGallery.css'
import { Link } from 'react-router-dom'
import gallery1 from "../../assets/img/gallery/gallery-1.png"
import gallery2 from "../../assets/img/gallery/gallery-2.png"
import gallery3 from "../../assets/img/gallery/gallery-3.png"
import gallery4 from "../../assets/img/gallery/gallery-4.png"
import gallery5 from "../../assets/img/gallery/gallery-5.png"
import gallery6 from "../../assets/img/gallery/gallery-6.png"
import gallery7 from "../../assets/img/gallery/gallery-7.png"
import gallery8 from "../../assets/img/gallery/gallery-8.png"

const CheckGallery = () => {
    const carouselRef = useRef();
    useEffect(() => {
        const items = carouselRef.current.querySelectorAll('.carousel .carousel-item');
        items.forEach((el) => {
            const minPerSlide = 4;
            let next = el.nextElementSibling;
            for (let i = 1; i < minPerSlide; i++) {
                if (!next) {
                    // wrap carousel by using first child
                    next = items[0];
                }
                const cloneChild = next.cloneNode(true);
                el.appendChild(cloneChild.children[0]);
                next = next.nextElementSibling;
            }
        });
    }, [carouselRef]);

    return (
        <section className="gallery">
            <div className='container'>
                <div className="section-title" data-aos="fade-up">
                    <h2>Gallery</h2>
                    <p>Check our Gallery</p>
                </div>
                <div className="text-center my-3">
                    <div className="row g-0 mx-auto my-auto justify-content-center" data-aos="fade-left">

                        <div id="recipeCarousel" className="carousel" data-bs-ride="carousel slide" ref={carouselRef}>
                            <div className="carousel-inner" role="listbox">
                                <div className="carousel-item active">
                                    <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="100">
                                            <img src={gallery1} className="img-fluid" />
                                        </div>
                                    </div>

                                </div>
                                <div className="carousel-item">
                                    <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="150">
                                            <img src={gallery2} className="img-fluid" />
                                        </div>
                                    </div>

                                </div>
                                <div className="carousel-item">
                                    <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="200">
                                            <img src={gallery3} className="img-fluid" />
                                        </div>
                                    </div>

                                </div>
                                <div className="carousel-item">
                                    <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="250">
                                            <img src={gallery4} className="img-fluid" />
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item">
                                    <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="300">
                                            <img src={gallery5} className="img-fluid" />
                                        </div>
                                    </div>

                                </div>

                                <div className="carousel-item">
                                    <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="350">
                                            <img src={gallery6} className="img-fluid" />
                                        </div>
                                    </div>

                                </div>

                                <div className="carousel-item">
                                    <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="400">
                                            <img src={gallery7} className="img-fluid" />
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item">
                                    <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                                            <img src={gallery8} className="img-fluid" />
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <a className="carousel-control-prev bg-transparent w-aut" href="#recipeCarousel" role="button"
                                data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            </a>
                            <a className="carousel-control-next bg-transparent w-aut" href="#recipeCarousel" role="button"
                                data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            </a>
                        </div>
                    </div>
                </div>



                <div className="row justify-content-center" data-aos="fade-left">
                    <Link to="gallery" className="btn btn-primary btn-features">Feel the experience</Link>
                </div>
            </div>
        </section>
    );
};

export default CheckGallery;
import React from 'react';
import AOS from 'aos';
import ReactGA from 'react-ga';
import 'aos/dist/aos.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import HomePage from './Components/HomePage/HomePage';
import SolutionPage from './Components/SolutionPage/SolutionPage';
import FeaturePage from './Components/FeaturePage/FeaturePage';
import GalleryPage from './Components/GalleryPage/GalleryPage';
import AboutPage from './Components/AboutPage/AboutPage';
import BlogPage from './Components/BlogPage/BlogPage';
import OurOfferPage from './Components/OurOfferPage/OurOfferPage';
import ContactUsPage from './Components/ContactUsPage/ContactUsPage';
import ThanksPage from './Components/ThanksPage/ThanksPage';
import NotFoundPage from './Components/NotFoundPage/NotFoundPage';
import Article1 from './Components/Articles/AI-and-the-Metaverse'
import Article2 from './Components/Articles/The-metaverse-an-upgrade-not-a-trend';
import Article3 from './Components/Articles/Blockchain-in-the-Metaverse'
import Article4 from './Components/Articles/Owniverse-own-your-metaverse';
import UpArrow from './Components/UpArrow';
import Owniverse from './Components/Game/Owniverse';
import MetaScrum from './Components/Game/MetaScrum';
import Insum from './Components/Game/Insum';
import MetaMain from './Components/MetaTalan/MetaMain';
class App extends React.Component {

  componentDidMount() {
    AOS.init();
    ReactGA.initialize('G-YTK4EBY5GM');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/metascrum" element={<MetaScrum />} />
            <Route path="/metatalan" element={<MetaMain />} />
            <Route path="/our-solutions" element={<SolutionPage />} />
            <Route path="/our-offer" element={<OurOfferPage />} />
            <Route path="/features" element={<FeaturePage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/thanks" element={<ThanksPage />} />
            <Route path="/owniverse" element={<Owniverse />} />
            <Route path="/Insum" element={<Insum />} />
            <Route path="/ai-and-the-metaverse" element={<Article1 />} />
            <Route path="/blockchain-in-the-metaverse" element={<Article3 />} />
            <Route path="/the-metaverse-an-upgrade-not-a-trend" element={<Article2 />} />
            <Route path="/owniverse-own-your-metaverse" element={<Article4 />} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
          <UpArrow />
        </div>
      </Router>
    );
  }
}

export default App;

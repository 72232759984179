import React, { useEffect } from 'react';
// import BookATour from '../BookATour'
import Navbar from '../Navbar';
import Footer from '../Footer';
import OurOffer from './OurOffer';
import HeroMain from '../HeroMain';

const SolutionPage = () => {
    useEffect(() => {
        document.title = 'Owniverse - Our offer';
        window.scrollTo(0, 0);
    }, []);

    return (
        <div >
            <Navbar />
            <HeroMain
                title="Our Offer"
                description="Being experts in the innovation and consulting in the digital transformation, we accompany you to co-build your solutions in the metaverse: in virtual environments involving 3D (desktop, mobile, web), AR (Augmented Reality), MR (Mixed Reality) and VR (Virtual Reality)."
                buttonText="Check our offer"
                buttonHref="#our-offer"
            />
            <OurOffer />
            <Footer />
        </div>


    );
};

export default SolutionPage;
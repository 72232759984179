import React from 'react';

const Contact = (props) => {

    return (
        <div className="col-sm mb-4" data-aos="fade-right" data-aos-delay="100">
            <i className={props.icon}></i>
            <h4>{props.title}</h4>
            <p>{props.description}</p>
        </div>
    );
};

export default Contact;

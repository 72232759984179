import React from 'react';
import './Loader.css';
import O from '../../assets/img/O.png'
import overlay from '../../assets/img/loading-overlay.png'

const Loader = (props) => {
    return (
        <>
            <img src={overlay} className='owniverse-bg' />
            <div className='cn'>
                <img src={O} />
            </div>
            <p className='loading-text'>Loading environment {props.loadingPercentage} %</p>
        </>
    );
};

export default Loader;

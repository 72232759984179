import React, { useEffect } from 'react';
import './Articles.css'
import owniverse_view from "../../assets/img/blog/owniverse_view.webp"
import Navbar from '../Navbar';
import Footer from '../Footer';
import imen from "../../assets/img/team/imen.webp"
import avatars from "../../assets/img/blog/avatars.PNG"
import owniverse_logo from "../../assets/img/owniverse_logo_blue.png"

const Article4 = () => {
    useEffect(() => {
        document.title = 'Owniverse - Blog';
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Navbar />
            <div className="article-header">
                <h1>Owniverse - Own your metaverse</h1><br />
                <img src={owniverse_view} alt="metaverse" />
            </div>
            <main id="main-article">
                <div className="flex-child author">
                    <div className="auth">
                        <img className="imgA1" src={imen} />
                    </div>
                    <p className="text-center">Imen</p>
                    <p className="bio text-center">CEO of Owniverse</p>
                    {/* <p>share on:
                        <a href="#" target="popup"
                            onclick="window.open('https://twitter.com/share?url=https://owniverse.org/blog/blockchain-in-the-metaverse.html&text=blockchain+and+the+metaverse&hashtags=owniverse',
                            'blockchain-and-the-metaverse','width=600,height=400')">
                            <i className="bi bi-twitter"></i>
                        </a>
                    </p> */}
                </div>

                <div className="flex-child">

                    <section className="article">
                        <h3>What we mean by Owniverse</h3>
                        <p>When we first started to design the metaverse, we never dreamed of coming
                            this far. At first, we only thought of experimenting and riding the wave of
                            trendiness. As we evolved and built MetaTalan (Talan’s meta workplace), our
                            perception of things changed. We began to perceive life differently. People say
                            when you nurture something, you develop the feeling of attachment.
                            Eventually, that “something” becomes your property, your own. Our journey
                            marked the birth of Owniverse: A customizable metaverse platform imagined at
                            Talan and built by the passionate and skilled Innovation Factory team.<br />
                            We want to accompany our clients on their own journey to find out their own
                            perception of the metaverse’s promises. Our job is to help them bring that
                            perception to life and assist them in building and owning their own metaverse
                            while upgrading their services and leveling up the experience of their own
                            customers.</p>
                        <img src={owniverse_logo} />
                        <h3>Why Owniverse</h3>
                        <p>With Owniverse, custom virtual spaces,
                            worlds, events, and experiences can be
                            designed, built, and animated with
                            augmented avatars. It allows us to bring
                            people together by transforming the
                            way they meet, do business, negotiate,
                            scale-up, and the list is just unbounded.
                            You can imagine the numerous new
                            opportunities that can be unlocked for
                            individuals and companies to boost their
                            growth through innovative solutions. <br />
                            One of the most appealing opportunities
                            that springs to mind is revamping the
                            professional collaboration experience.
                            Collaboration, a key aspect of the
                            corporate world, was heavily impacted
                            during the Covid pandemic, as all the
                            stakeholders: including employees,
                            managers, decision-makers, and
                            customers were unable to reach out to
                            each other efficiently because of the
                            global restrictions issued. Platforms like
                            Zoom, Microsoft Teams, and Google
                            meet became the new norm for remote
                            work. Even though collaboration and
                            customer relationships were saved
                            during the pandemic period, many
                            limitations messed up the online
                            experience such as lack of
                            immersiveness and lack of proximity
                            feeling, especially for customers.
                            Owniverse promises to solve these
                            challenges by improving immersion and
                            by enabling a more connected company
                            community, hence yielding a more
                            productive environment, increasing staff
                            engagement, and nurturing the
                            customer relationship.<br />
                            Beyond the scope of Covid19 and
                            remote work, Owniverse promises to
                            offer an augmented alternative to a
                            real-world collaboration experience by
                            providing advanced built-in features. namely teleportation, MoM automatic
                            generation, real-time translation,
                            managing the absence (this is trickier
                            than managing the presence), etc. <br />
                            Sure, we are only scratching the surface
                            of possibilities regarding what can be
                            done with Owniverse. Virtual events,
                            conferences, concerts, and much more
                            can be done in the metaverse with a
                            high level of immersion and excitement.
                            Sometimes, it may be even better to
                            conduct your events virtually as you will
                            attract more attendees, expand your
                            reach and you be able to choose your
                            preferred environment (a beach, a
                            stadium, a conference room ...) and
                            fully customize it. Remember, in this
                            virtual world, you are only limited by
                            your imagination.
                        </p>
                        <h3>Digital human</h3>
                        <h4>What is a digital human?</h4>
                        <p>
                            A digital human is an AI-powered
                            life-like digital being (avatar) that
                            offers the best experience in human
                            interaction and conversation. Digital
                            humans can exist both in the
                            physical (public spaces,
                            supermarkets, etc.) and the digital
                            worlds (websites, the 3D virtual
                            space, etc.).</p>
                        <img src={avatars} alt='avatars' />
                        <h4>What makes digital humans so
                            unique?</h4>
                        <p>Digital humans interact with us
                            through verbal and non-verbal
                            communication. Like us, they can
                            exhibit facial expressions, body
                            language, and different voice tones
                            to convey their emotions, making
                            the conversation flow naturally.
                            Moreover, Digital humans, unlike
                            regular customer services, are
                            available 24 hours a day and 7 days
                            a week [1].
                        </p>
                        <h4>Digital humans that exist in the
                            market</h4>
                        <p>The digital human market is Growing
                            rapidly. It is expected to reach the
                            size of $527.58 Billion in 2030.
                            Indeed, digital humans are gradually
                            being integrated into many
                            industries including education, retail,
                            etc [2].<br />
                            The use of digital humans in
                            education, for example, provides a
                            personalized, and an emotionally
                            reverberant experience for the
                            students, resulting in, higher focus
                            and better assimilation of the
                            information.<br />
                            In retail, digital humans can be used
                            especially in customer service.
                            Thanks to their availability and
                            convincement, they can increase
                            sales considerably.<br />
                            Many renowned IT companies are
                            betting on the digital human market
                            including <a href='https://digitalhumans.com/'>UneeQ</a>, <a href='https://www.unrealengine.com/en-US/metahuman'>Epic Games</a>,
                            <a href='https://www.didimo.co/'>Didimo</a>, <a href='Didimo'>DeepBrain AI</a>, <a href="https://www.soulmachines.com/">Soul Machines</a>
                            , etc.</p>
                        <h4>Digital human and ethics</h4>
                        <p>Since Digital humans are virtual copies of real human beings there is
                            a set of rules that must be respected
                            known as the five laws of ethical
                            digital human design [3]:
                            <ul>
                                <li>Digital humans shall never
                                    pretend to be what they’re not.</li>
                                <li>Digital humans should always
                                    be designed to help not harm.</li>
                                <li>Digital humans must respect
                                    the privacy of those they
                                    interact with.</li>
                                <li>Digital humans should be
                                    designed to uphold respectful
                                    behavior.</li>
                                <li>Co-design of digital humans
                                    should be adopted to avoid
                                    bias and promote diversity and
                                    inclusive representation.
                                </li>
                            </ul>
                        </p>
                        <h4>Digital human and self-sovereign
                            identity</h4>
                        <p>As digital humans can impersonate
                            real human beings, it is imperative
                            to make them unique and accessible
                            only to their owners. In this aspect,
                            self-sovereign identity comes in
                            handy. It enables users to have a
                            unique identifier that represents
                            them or, in this case, their digital
                            human. This identity will not be
                            managed or controlled by a single
                            organization/world, but it will be
                            decentralized, controlled by the user
                            himself, and accepted anywhere in
                            the Metaverse.</p>
                        <h4>Integration in Owniverse</h4>
                        <p>
                            As the next step to promote the user
                            experience in Owniverse, we intend
                            to integrate digital humans to
                            impersonate collaborators/avatars in
                            case of unavailability. In that case,
                            people will interact comfortably while exploiting the full potential of the
                            platform.
                        </p>
                        <h3>NFTs</h3>
                        <p>With Owniverse, NFTs (short for
                            non-fungible tokens) can be an
                            important building block for
                            constructing your custom metaverse.
                            Not only do NFTs provide a way for
                            the users of your Owniverse to own
                            unique items with a digital
                            possession proof stored on the
                            blockchain making them the true
                            owners of their assets, but it also
                            makes a good tool to engage
                            communities and reward highly loyal
                            users of your interactive 3D
                            environment with special editions of
                            NFTs. And coupling this with the use
                            of gamification techniques, users can
                            be incentivized to pass more time in
                            the metaverse and interact more
                            with its components.</p>
                        <p>
                            Maybe your users want their avatars
                            to wear special gadgets, maybe they
                            want to put on some special
                            clothing, or perhaps they want to
                            acquire a digital asset in the
                            possession of another user. NFTs can
                            be helpful in these cases and users
                            who are keen on exchanging and
                            acquiring digital assets can do this
                            either using your marketplace or via
                            an external one.
                        </p>
                        <h3>Conclusion</h3>
                        <p>It may not be clear why people would
                            ever want to change the way they
                            interact and cooperate with each other
                            on the internet. Throughout history,
                            humanity resisted technological change
                            at first but embraced it later, and this is especially if the change is sudden and
                            radical. The Covid
                            -19 pandemic taught
                            us that we need to quickly adapt and
                            make fast first moves.</p><br /><br />

                        <h3>References</h3>

                        [1]<a href='https://www2.deloitte.com/nl/nl/pages/customer-and-marketing/articles/digital-human.html'>https://www2.deloitte.com/nl/nl/pages/customer-and-marketing/articles/digital-human.html</a><br />

                        [2]<a href='https://www.emergenresearch.com/industry-report/digital-human-avatar-market' >https://www.emergenresearch.com/industry-report/digital-human-avatar-market </a><br />

                        [3]<a href='https://insights.digitalhumans.com/hubfs/Newex%20Landing%20Page/The%20Five%20Laws%20of%20Ethical%20Digital%20Human%20Design%20(FINAL).pdf'>https://insights.digitalhumans.com/hubfs/Newex%20Landing%20Page/The%20Five%20Laws%20of%20Ethical%20Digital%20Human%20Design%20(FINAL).pdf</a>
                    </section>
                </div>
            </main >
            <Footer />
        </div >
    );
};

export default Article4;

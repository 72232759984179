import React from 'react';

import HeroIn from '../../assets/videos/Hero-In -Min.mp4'
import Kitchen from '../../assets/videos/Kitchen-Min.mp4'
import Mcdonalds from '../../assets/videos/Mcdonalds-Min.mp4'
import OwniverseMin from '../../assets/videos/Owniverse-Min.mp4'
import Perfume from '../../assets/videos/Perfume-Min.mp4'
import Shopping from '../../assets/videos/Shopping-Min.mp4'

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import './HeroDisplay.css'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

const HeroDisplay = (props) => {
    return (
        <div>
            <section id="herosection">
                <Swiper
                    className="mySwiper"
                    slidesPerView={1}
                    pagination={{
                        dynamicBullets: true,
                        clickable: true
                    }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    spaceBetween={75}
                    modules={[Pagination, Navigation, Autoplay]}
                    loop={true}
                    navigation={true}
                    autoHeight={true}
                >
                    <SwiperSlide className="cont">
                        <div className="cont">
                            <div className="bg-video overlay">
                                <video muted autoPlay loop className='video'>
                                    <source src={HeroIn} type="video/mp4" />
                                </video>
                            </div>
                            <div data-aos="zoom-out" className='container base'>
                                <h1>Sports</h1>
                                <h2>HERO-IN</h2>
                                <h3>A Sports Complex Metaverse where anyone can buy and sell a piece of land  consisting of multi-purpose Areas.</h3>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="cont">
                            <div className="bg-video overlay">
                                <video muted autoPlay loop className='video'>
                                    <source src={Kitchen} type="video/mp4" />
                                </video>
                            </div>
                            <div data-aos="zoom-out" className='container base'>
                                <h1>Real Estate</h1>
                                <h2>IMMERSIVE ​KITCHEN. </h2>
                                <h3>An immersive experience where you can fully customize and interact with the different scene elements.</h3>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="cont">
                        <div className="cont">
                            <div className="bg-video overlay">
                                <video muted autoPlay loop className='video'>
                                    <source src={Mcdonalds} type="video/mp4" />
                                </video>
                            </div>
                            <div data-aos="zoom-out" className='container base'>
                                <h1>Delivery</h1>
                                <h2>McDonald’s</h2>
                                <h3>McDonald's brings people together and makes their meals taste better even if they are physically separated.
                                    You can order your meal in the metaverse and receive it both virtually and physically.</h3>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="cont">
                            <div className="bg-video overlay">
                                <video muted autoPlay loop className='video'>
                                    <source src={OwniverseMin} type="video/mp4" />
                                </video>
                            </div>
                            <div data-aos="zoom-out" className='container base'>
                                <h1>Collaboration</h1>
                                <h2>METATALAN</h2>
                                <h3>MetaTalan is the ultimate solution to ensure the best onboarding process for new collaborators. </h3>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="cont">
                        <div className="cont">
                            <div className="bg-video overlay">
                                <video muted autoPlay loop className='video'>
                                    <source src={Perfume} type="video/mp4" />
                                </video>
                            </div>
                            <div data-aos="zoom-out" className='container base'>
                                <h1>Luxury</h1>
                                <h2>IMMERSIVE SHOPPING</h2>
                                <h3> An immersive toolbox for luxury products, enhanced with olfactory powers, which purpose is to upgrade the customer experience in free shops and beauty stores.
                                    We worked on unleashing the olfactory imagery by developing an immersive portal coupled with a virtual stimulus powered by an “in-house” created hardware gadget</h3>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="cont">
                            <div className="bg-video overlay">
                                <video muted autoPlay loop className='video'>
                                    <source src={Shopping} type="video/mp4" />
                                </video>
                            </div>
                            <div data-aos="zoom-out" className='container base'>
                                <h1>Luxury</h1>
                                <h2>IMMERSIVE SHOPPING (POC)</h2>
                                <h3>We imagined an extended online shopping experience for CELINE products. A cross media solution: augmented web page, 3D spaces and instantaneous link with social media. </h3>
                            </div>
                        </div>
                    </SwiperSlide>

                    <svg className="hero-waves"
                        viewBox="0 24 150 28 " preserveAspectRatio="none">
                        <defs>
                            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="wave3">
                            <use href="#wave-path" x="50" y="9" fill="#fff" />
                        </g>
                    </svg>
                </Swiper>
                {/* <svg className="hero-waves"
                    viewBox="0 24 150 28 " preserveAspectRatio="none">
                    <defs>
                        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="wave3">
                        <use href="#wave-path" x="50" y="9" fill="#fff" />
                    </g>
                </svg> */}
            </section>
        </div>
    );
};

export default HeroDisplay;
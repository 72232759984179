import React from 'react';
import "./ContactUs.css"
import Contact from '../Contact';
import Form from '../ContactUsPage/Form'
const ContactUs = () => {
    return (
        <section className="contact">
            <div className="container">
                <div className="section-title" data-aos="fade-up">
                    <h2>Contact</h2>
                    <p>Contact Us</p>
                </div>
                <div className="row">
                    <div className="col-lg-4" data-aos="fade-right" data-aos-delay="100">
                        <div className="info">
                            <Contact icon="bi bi-phone" title="Call:" description="+216 70 015 065" />
                            <Contact icon="bi bi-envelope" title="Email:" description="support@owniverse.org" />
                            <div className="address">
                                <Contact icon="bi bi-geo-alt" title="Location:" description="10 Rue de l'énergie solaire Impasse N°1 Charguia 1، Tunis 2035" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-5 mt-lg-0 offset-1" data-aos="fade-left" data-aos-delay="200">
                        <Form />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;

import React from 'react';

const Feature = (props) => {
    const { title, icon, color } = props
    return (
        <div className="col-lg-3 col-md-3 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="450">
                <i className={icon} style={{ color: color }}></i>
                <h3 className="ff">{title}</h3>
            </div>
        </div>
    );
};

export default Feature;
import React, { useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { AvatarCreator, AvatarCreatorConfig, AvatarCreatorEvent } from '@readyplayerme/react-avatar-creator';
import axios from 'axios';
import "./TestBuild.css"
import '../Game/Owniverse.css';
import LoadingScreen from "./LoadingScreen";

function MetatalanBuild({ onDisconnect }) {

    const [updateAvatarFrame, setupdateAvatarFrame] = useState(false)


    const config: AvatarCreatorConfig = {
        clearCache: true,
        bodyType: 'fullbody',
        quickStart: true,
        language: 'en',
    };
    const style = { width: '100%', height: '95vh', zIndex: 99, borderRadius: "25px", };

    const { unityProvider, UNSAFE__unityInstance, sendMessage, addEventListener, removeEventListener, unload, isLoaded, loadingProgression, requestPointerLock } = useUnityContext({
        loaderUrl: "metatalanBuild/metatalan.loader.js",
        dataUrl: "metatalanBuild/metatalan.data",
        frameworkUrl: "metatalanBuild/metatalan.framework.js",
        codeUrl: "metatalanBuild/metatalan.wasm",
        // loaderUrl: "http://10.2.1.19/build/metatalan.loader.js",
        // dataUrl: "http://10.2.1.19/build/metatalan.data",
        // frameworkUrl: "http://10.2.1.19/build/metatalan.framework.js",
        // codeUrl: "http://10.2.1.19/build/metatalan.wasm",
    });

    async function disconnect() {
        await unload();
        onDisconnect()
    }


    const handleUpdateAvatar = () => {
        console.log("updating avatar")
        setupdateAvatarFrame(true)

    }

    const handleOnAvatarExported = (event) => {
        const avatarURL = event.data.url;
        console.log("avatar url", avatarURL)
        const uid = localStorage.getItem("uid")
        const token = localStorage.getItem("jwtToken")
        const endpoint = "update-avatar"
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        if (avatarURL) {
            axios.put(`${process.env.REACT_APP_SERVER_URL}${endpoint}`, {
                "uid": uid,
                "newAvatarURL": avatarURL
            }, {
                headers: headers
            })
                .then((response) => {
                    if (response.status !== 200) {
                        return;
                    }
                    setupdateAvatarFrame(false)
                    sendMessage("CharacterSelect", "updateAvatar");
                })
                .catch((err) => {
                    console.log("GOT ERROR", err);
                });
        }
    }

    useEffect(() => {
        window.unityInstance = UNSAFE__unityInstance;
    }, [UNSAFE__unityInstance]);


    useEffect(() => {
        const jwtToken = localStorage.getItem("jwtToken")
        const uid = localStorage.getItem("uid")
        const loginObject = uid + " " + jwtToken
        sendMessage("connectObject", "setLoginInfo", loginObject);
    }, [isLoaded]);


    useEffect(() => {
        addEventListener("updateAvatar", handleUpdateAvatar);
        return () => {
            removeEventListener("updateAvatar", handleUpdateAvatar);
        };
    }, [addEventListener, removeEventListener, handleUpdateAvatar]);

    const handleCustomEvent = (event: AvatarCreatorEvent) => {
        console.log("Received custom event", event);
    };

    function handleClick() {
        requestPointerLock();
    }

    return (
        <>
            {!isLoaded &&
                <LoadingScreen loadingPercentage={loadingProgression * 100} />}
            <div>
                {
                    updateAvatarFrame &&
                    <div className="update-avatar">
                        <div className="rpm">
                            <i className="bi bi-x-circle-fill fs-3  closeButton" aria-hidden="true" onClick={() => setupdateAvatarFrame(false)}></i>
                            <div onClick={handleClick}>
                                <AvatarCreator subdomain="metatalan" config={config} style={style} onAvatarExported={handleOnAvatarExported}

                                />
                            </div>
                        </div>
                    </div>
                }
                <Unity unityProvider={unityProvider}
                    className="my-unity-app"
                />

                {/* {isLoaded && <i className="bi bi-box-arrow-in-left disconnect" onClick={disconnect}></i>} */}
            </div>
        </>
    )

}

export default MetatalanBuild
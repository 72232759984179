import React from 'react';
import './Clients.css';
import logoTalan from "../../assets/img/logo-talan.png";

const Clients = () => {

    return (
        <section className="counts">
            <div className='container'>
                <div className="row" data-aos="fade-up">
                    <div className="section-title" data-aos="fade-up">
                        <h2>Clients</h2>
                        <p>They trusted us</p>
                    </div>
                    <div align="center" data-aos="fade-right">
                        <img src={logoTalan} className="img-fluid" alt="logo" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Clients;

import React from 'react';
import "./Card.css"

const Card = (props) => {
    const { title, description, image } = props
    return (
        <div className="col-sm" data-aos="fade-up">
            <div className="image-flip" onTouchStart="this.classList.toggle('hover');">
                <div className="mainflip">
                    <div className="frontside">
                        <div className="card-value">
                            <div className="card-value-body text-center">
                                <p><img className="" src={image} alt="card image" /></p>
                                <h4 className="card-value-title">{title}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="backside">
                        <div className="card-value">
                            <div className="card-value-body text-center mt-4">
                                <h4 className="card-value-title">{title}</h4>
                                <p className="card-value-text">{description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
import React, { useEffect, useState } from 'react'
import "./VrBox.css"
import vrHeadset from "../../assets/img/vr.png"
import backBtn from "../../assets/img/back.svg"
import line from "../../assets/img/line-58.svg"
import ConnectButton from '../ConnectButton/ConnectButton';
import MicrosoftLogin from "react-microsoft-login";

export default function VrBox({ focus, unfocus, handleLogin, vrCode }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [codeReady, setCodeReady] = useState(false);
    const [shrink, setShrink] = useState(false);

    const handleCardClick = () => {
        if (!isExpanded) {
            setShrink(false)
            setIsExpanded(true);
            focus()
        }
    };

    const handleBackClick = () => {
        setShrink(true)
        setIsExpanded(false);
        setCodeReady(false)
        unfocus()
    };


    useEffect(() => {
        if (vrCode !== null) {
            setIsExpanded(true);
            setCodeReady(true)
        }
    }, [vrCode])

    return (
        <div className={`cardvr ${isExpanded ? 'expanded' : ''} ${codeReady ? 'codeReady' : ''}  ${shrink ? 'shrink' : ''}`} onClick={handleCardClick}>
            {/* <div className="rectangle" /> */}
            <img className='vr-headset' alt="vr-headset" src={vrHeadset} />
            <img className='back-btn' alt="back" src={backBtn}
                onClick={handleBackClick
                }
            />
            <div className="text-wrapper">Virtual Reality</div>
            <div className="vr-desc">
                <p>Explore the fully immersive experience with your VR Headset</p>
            </div>
            <div className="d-vr">
                <p>Download Metatalan app to your VR Headset from this link:
                    <b> https://metatalan.com/downloadVR </b></p>
            </div>
            <div className='connect-btn'>
                <MicrosoftLogin forceRedirectStrategy={false} clientId={process.env.REACT_APP_MICROSOFT_ID} authCallback={handleLogin} tenantUrl={process.env.REACT_APP_TENANT_URL} redirectUri={process.env.REACT_APP_REDIRECT_URI}
                    children={<ConnectButton />}
                />
            </div>
            <div className='conn'>
                <div className="connected">Connected</div>
                <img className='line' alt='line' src={line} />
                <p className='ins'>Copy This <b>Code</b> to Your VR App</p>
                <div className='vrBox'>
                    <div className='vrRectangle'>
                        <div className="code">{vrCode}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

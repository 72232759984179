import React, { useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import './Owniverse.css'
import Loader from "./Loader";


function MetaScrum() {

    const { unityProvider, isLoaded, loadingProgression, UNSAFE__unityInstance } = useUnityContext({
        loaderUrl: "metaScrum/metaScrum.loader.js",
        dataUrl: "metaScrum/metaScrum.data",
        frameworkUrl: "metaScrum/metaScrum.framework.js",
        codeUrl: "metaScrum/metaScrum.wasm",
    });

    const loadingPercentage = Math.round(loadingProgression * 100);

    useEffect(() => {
        window.unityInstance = UNSAFE__unityInstance;
    }, [UNSAFE__unityInstance]);


    return (
        <>
            <div>
                {isLoaded === false && (
                    <Loader loadingPercentage={loadingPercentage} />
                )}
                <Unity unityProvider={unityProvider}
                    className="my-unity-app"
                />

            </div>
        </>
    )

}

export default MetaScrum
import React, { useState } from 'react';
import owniverse_view from '../../assets/img/owniverse-trailer.png'
import './Solution.css'
import FsLightbox from "fslightbox-react";


const Solutions = () => {
  const [toggler, setToggler] = useState(false);
  return (
    <section className="about mb-5">
      <FsLightbox
        sources={['https://www.youtube.com/watch?v=__XKJQpWDv0']}
        toggler={toggler}
      />
      <div className="container">

        <div className="row justify-content-center">
          <div className="gif " data-aos="fade-right">
            <img src={owniverse_view} className="img-fluid rounded-5" onClick={() => setToggler(!toggler)} />
          </div>

          <div
            className="col-xl-5 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
            data-aos="fade-left">
            <h3>We design the virtual world you always dreamt of</h3>
            <p>Build awesome custom virtual worlds to engage your community and bring people together in enjoyable
              immersive experiences.</p>
          </div>
        </div>
      </div>

    </section>
  );
};

export default Solutions;
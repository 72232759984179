import React from 'react';
import './HeroMain.css'
import Owniverse from "../assets/videos/Owniverse.mp4"

const HeroMain = (props) => {
    return (
        <div id="herosection">
            <div className="cont">
                <div className="bg-video overlay">
                    <video muted autoPlay loop className='video'>
                        <source src={Owniverse} type="video/mp4" />
                    </video>
                </div>

                <div data-aos="zoom-out" className='container base'>
                    <h1>{props.title}</h1>
                    <h2>{props.description}
                    </h2>
                    <div className="text-center text-lg-start ">
                        <a href={props.buttonHref} className="btn-get-started scrollto">{props.buttonText}</a>
                    </div>
                </div>

            </div>
            <svg className="hero-waves"
                viewBox="0 24 150 28 " preserveAspectRatio="none">
                <defs>
                    <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="wave1">
                    <use href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                </g>
                <g className="wave2">
                    <use href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                </g>
                <g className="wave3">
                    <use href="#wave-path" x="50" y="9" fill="#fff" />
                </g>
            </svg>

        </div >
    );
};

export default HeroMain;
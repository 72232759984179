import React from 'react';
import avatars from '../../assets/img/avatars.webp'
import { Link } from 'react-router-dom'
import Navbar from '../Navbar';
import Footer from '../Footer';

const NotFoundPage = () => {

    return (
        <div>
            <Navbar />
            <section>
                <div className="jumbotron text-center">
                    <h1 className="display-3">Ooops ! This page is not found</h1>
                    <p className="lead">
                        <Link className="btn submit" to="/" role="button">Back to homepage</Link>
                    </p>
                    <img src={avatars} style={{ width: "60%" }} />
                </div>
            </section>
            <Footer />
        </div>

    );
};

export default NotFoundPage;

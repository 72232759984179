import React from 'react';
import './HeroSection.css'
import Typing from "./Typing"
import Owniverse from "../../assets/videos/Owniverse.mp4"
import { Link } from 'react-router-dom';


const HeroSection = () => {
    return (
        <div id="herosection">

            <div className="cont">
                <div className="bg-video overlay">
                    <video muted autoPlay loop className='video'>
                        <source src={Owniverse} type="video/mp4" />
                    </video>
                </div>

                <div data-aos="zoom-out" className='container base'>
                    <h1>Change the way you</h1>
                    <Typing />
                    <h2>Get fully customizable spaces, own your metaverse and enjoy immersive experiences.
                    </h2>
                    <div className="text-center text-lg-start ">
                        {/* <a href="https://owniverse.org/app.html" target="_blank" className="btn-get-started scrollto">Enter the Owniverse</a> */}
                        <Link to='/owniverse' target="_blank" className="btn-get-started scrollto">Enter the Owniverse</Link>
                    </div>
                </div>

            </div>
            <svg className="hero-waves"
                viewBox="0 24 150 28 " preserveAspectRatio="none">
                <defs>
                    <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="wave1">
                    <use href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                </g>
                <g className="wave2">
                    <use href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                </g>
                <g className="wave3">
                    <use href="#wave-path" x="50" y="9" fill="#fff" />
                </g>
            </svg>

        </div >
    );
};

export default HeroSection;
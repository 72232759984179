import React, { useState } from 'react';
import gallery1 from "../../assets/img/gallery/gallery-1.png"
import gallery2 from "../../assets/img/gallery/gallery-2.png"
import gallery3 from "../../assets/img/gallery/gallery-3.png"
import gallery4 from "../../assets/img/gallery/gallery-4.png"
import gallery5 from "../../assets/img/gallery/gallery-5.png"
import gallery6 from "../../assets/img/gallery/gallery-6.png"
import gallery7 from "../../assets/img/gallery/gallery-7.png"
import gallery8 from "../../assets/img/gallery/gallery-8.png"
import gallery9 from "../../assets/img/gallery/gallery-9.png"
import gallery10 from "../../assets/img/gallery/gallery-10.png"
import gallery11 from "../../assets/img/gallery/gallery-11.png"
import gallery12 from "../../assets/img/gallery/gallery-12.png"
import FsLightbox from "fslightbox-react";
import Navbar from '../Navbar';

const Gallery = () => {

    const [toggler, setToggler] = useState(false);
    const [source, setSource] = useState('');

    return (
        <section id="gallery" className="gallery">
            <Navbar />
            <FsLightbox
                sources={[source]}
                toggler={toggler}
            />
            <div className='container'>
                <div className="section-title" data-aos="fade-up">
                    <h2>Gallery</h2>
                    <p>Check our Gallery</p>
                </div>

                <div className="row g-0" data-aos="fade-left">

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="100">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery1); }} src={gallery1} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="150">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery2); }} src={gallery2} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="200">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery3); }} src={gallery3} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="250">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery4); }} src={gallery4} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="300">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery5); }} src={gallery5} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="350">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery6); }} src={gallery6} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="400">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery7); }} src={gallery7} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery8); }} src={gallery8} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery9); }} src={gallery9} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery10); }} src={gallery10} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery11); }} src={gallery11} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                            <img onClick={() => { setToggler(!toggler); setSource(gallery12); }} src={gallery12} className="img-fluid" />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default Gallery;
import React, { useState, useEffect } from "react";
import RPM from "../RPM/RPM"
import MetaTalan from "./MetaTalan";
import MetatalanBuild from "../metatalanBuild/metatalanBuild"
import ErrorBox from "../ErrorBox/ErrorBox";
import axios from 'axios';

export default function MetaMain() {
    const [rpm, setRpm] = useState(false);
    const [signedIn, setSignedIn] = useState(false);
    const [errorToggle, setErrorToggle] = useState(false)
    const [vrCode, setVrCode] = useState(null)
    const [platform, setPlatform] = useState(null)
    const [avatarURL, setAvatarURL] = useState(null)

    const handleOnAvatarExported = (event) => {
        const avatarURL = event.data.url;
        const uid = localStorage.getItem("uid")
        const token = localStorage.getItem("jwtToken")
        const endpoint = "update-avatar"
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        if (avatarURL) {
            axios.put(`${process.env.REACT_APP_SERVER_URL}${endpoint}`, {
                "uid": uid,
                "newAvatarURL": avatarURL
            }, {
                headers: headers
            })
                .then((response) => {
                    if (response.status !== 200) {
                        return;
                    }
                    if (platform === "browser") {
                        setSignedIn(true);
                        setRpm(false);
                    }
                    if (platform === "VR") {
                        setSignedIn(false);
                        setRpm(false);
                    }
                    return response.data;
                })
                .catch((err) => {
                    console.log("GOT ERROR", err);
                });
        }
    };

    const handlePlatformChange = (platform) => {
        setPlatform(platform)
    }

    const loginHandler = (err, data) => {
        const endpoint = 'connect'
        if (!err && data) {
            const user = {
                "uid": data["account"]["homeAccountId"],
                "username": data["account"]["name"],
                "email": data["account"]["username"]
            }

            localStorage.setItem('uid', data["account"]["homeAccountId"]);
            fetch(`${process.env.REACT_APP_SERVER_URL}${endpoint}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(user),
            })
                .then((response) => {

                    if (response.status !== 200) {
                        setErrorToggle(true)
                        return
                    }
                    return response.json()
                }

                )
                .then((data) => {
                    localStorage.setItem('jwtToken', data["jwt"]);
                    setAvatarURL(data["user"]["avatarURL"])
                    setVrCode(data["user"]["vrCode"])
                    if (data["user"]["avatarURL"] === null && platform === "browser") {
                        setRpm(true)
                        setSignedIn(true)
                        return
                    }

                    if (data["user"]["avatarURL"] !== null && platform === "browser") {
                        setRpm(false)
                        setSignedIn(true)
                        return
                    }
                    if (data["user"]["avatarURL"] === null && platform === "VR") {
                        setRpm(true)
                        setSignedIn(true)
                        return
                    }

                })

                .catch((error) => {
                    setErrorToggle(true)
                    console.log("error occurred: ", error);
                });

        }
    };


    useEffect(() => {
        if (localStorage.getItem("uid") !== null && localStorage.getItem("jwtToken") !== null && avatarURL !== null && platform === "browser") {
            setSignedIn(true)
            setRpm(false)
        }
    }, [])

    const logoutHandler = () => {
        setSignedIn(false)
        localStorage.removeItem('jwtToken')
        localStorage.removeItem('uid')
        setVrCode(null)
    };

    const closeErrorBox = () => {
        setErrorToggle(false)
    }

    return (
        <>
            {errorToggle ?
                (<ErrorBox title="Connection Error" message="Something went wrong!" onClose={closeErrorBox} />) :
                <></>
            }
            {!signedIn ? (
                <MetaTalan handleLogin={loginHandler} vrCode={vrCode} handlePlatformChange={handlePlatformChange} />
            ) : rpm ? (
                <RPM handleOnAvatarExported={handleOnAvatarExported} />
            ) : (
                <MetatalanBuild onDisconnect={logoutHandler} />
            )}
        </>
    )
}
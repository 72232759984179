import React, { useEffect } from 'react';
import avatars from "../../assets/img/avatars.webp"
import { Link } from 'react-router-dom'

import Footer from '../Footer';
import Navbar from '../Navbar';

const ThanksPage = () => {
    useEffect(() => {
        document.title = 'Owniverse - Thank you';
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Navbar />
            <section>
                <div className="jumbotron text-center">
                    <h1 className="display-3">Thank You!</h1>
                    <p className="lead"><strong>Your request is submitted successfully.</strong> Your submission will be reviewed and we will contact you soon.</p>
                    <p className="lead">
                        <Link className="btn submit" to="/" role="button">Continue to homepage</Link>
                    </p>
                    <img src={avatars} style={{ width: "60%" }} />
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ThanksPage;
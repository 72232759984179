import React, { useEffect } from 'react';
import HeroDisplay from './HeroDisplay';
import Navbar from '../Navbar';
import Footer from '../Footer';
import VideoCard from './VideoCard';

const SolutionPage = () => {
    useEffect(() => {
        document.title = 'Owniverse - Our solutions';
        window.scrollTo(0, 0);
    }, []);

    return (
        <div >
            <Navbar />
            <HeroDisplay />
            <VideoCard />
            <Footer />
        </div>


    );
};

export default SolutionPage;
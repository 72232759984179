import React from 'react';
import "./OurValues.css"
import Card from "./Card"
import teamspirit from "../../assets/img/cards/teamspirit.webp"
import respect from "../../assets/img/cards/respect.webp"
import innovation from "../../assets/img/cards/innovation.webp"
import trust from "../../assets/img/cards/trust.webp"

const OurValues = () => {
    return (
        <section id="team" className="pb-5">
            <div className="container">
                <div className="section-title" data-aos="fade-up">
                    <h2>our values</h2>
                    <p>we believe in</p>
                </div>
                <div className="row">
                    <Card title="Collaboration"
                        description="We rely on our creative minds to offer the best experience for our clients, and we strongly believe in the synergy between professionals, academicians, and students to challenge frontiers inside the local and global communities of open innovation."
                        image={teamspirit}
                    />
                    <Card
                        title="Team power "
                        description="We believe that team spirit is the key to moving forward. Since we are a group of lively and enthusiastic people, we welcome any challenge with open arms. "
                        image={respect}
                    />
                    <Card
                        title="Respect"
                        description="Respect is a fundamental attitude in any human interaction. Since we value our peers and customers alike, we treat them with utmost courtesy and respect. "
                        image={innovation}
                    />
                    <Card
                        title="Trust"
                        description="Our relationship with our clients is built on trust. We always strive to make our services better, reassure our customers, and gain their faith. "
                        image={trust}
                    />
                </div>
            </div>
        </section>
    );
};

export default OurValues;
import React, { useEffect, useRef } from 'react';

const UpArrow = () => {
    const backtotopRef = useRef(null);

    useEffect(() => {
        const toggleBacktotop = () => {
            if (window.scrollY > 100) {
                backtotopRef.current.classList.add('active');
            } else {
                backtotopRef.current.classList.remove('active');
            }
        };

        window.addEventListener('load', toggleBacktotop);
        window.addEventListener('scroll', toggleBacktotop);

        return () => {
            window.removeEventListener('load', toggleBacktotop);
            window.removeEventListener('scroll', toggleBacktotop);
        };
    }, []);

    return (
        <a href="#" className="back-to-top d-flex align-items-center justify-content-center" ref={backtotopRef}>
            <i className="bi bi-arrow-up-short" />
        </a>
    );
};

export default UpArrow;

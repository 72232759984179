import React, { useEffect } from 'react';
import nesrine from "../../assets/img/blog/nesrine.png"
import ai from "../../assets/img/blog/aiMetaverse.webp"
import './Articles.css'
import Navbar from '../Navbar';
import Footer from '../Footer';

const Article1 = () => {
    useEffect(() => {
        document.title = 'Owniverse - Blog';
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Navbar />
            <div className="article-header">
                <h1>AI and the Metaverse</h1><br />
                <img src={ai} alt="artificial intelligence" />
            </div>
            <main id="main-article">
                <div className="flex-child author">
                    <img src={nesrine} />
                    <p>Nesrine</p>
                    <p className="bio">AI consultant</p>
                    {/* <p>share on:
                        <a href="#" target="popup"
                            onclick="window.open('https://linkedin.com/sharing/share-offsite?url=https://owniverse.org/blog/ai-and-metaverse.html','AI and the Metaverse','width=600,height=400')" />
                        <i className="bi-linkedin"></i>
                        <a href="#" target="popup"
                            onclick="window.open('https://twitter.com/share?url=https://owniverse.org/blog/ai-and-metaverse.html&text=AI+and+the+Metaverse&hashtags=owniverse','AI and the Metaverse','width=600,height=400')">
                            <i className="bi bi-twitter"></i>
                        </a>
                    </p> */}
                </div>

                <div className="flex-child">

                    <section className="article">

                        <h3>Introduction</h3>
                        <p>Nowadays, Metaverse is a hot topic that has moved from the buzzword territory to real world app. In metaverse
                            experience, users are present in the digital content rather than observing it from the outside. Companies
                            worldwide use VR, MR, AR, along with technologies such as AI and blockchain to offer services in virtual
                            environment.<br />
                            In particular, our client Talan Tunisia Consulting marks its experience in Metaverse through its virtual world
                            MetaTalan.
                            This universe is created to facilitate the onboarding process for new recruits through digital experience.
                            Although Talan 3D world can exist without AI, combining the two brings a whole new level of immersive realism.
                            It is in this context that Talan’s first initiative in metaverse is being taken. In this article, we will
                            provide an overview on Talan virtual world, emphasize the need of AI in MetaTalan and reveal the chosen
                            approach . </p>

                        <h3>Meta talan Universe</h3>
                        <p>Metalan is a new era of collaboration where new recruits integrate virtual universe to live their onboarding
                            experience while interacting with the other collabs. At first, the new recruit chooses its own avatar and room
                            for signing in. Once connected, he will be guided in Talan virtual world by his manager to fulfill the
                            onboarding process.<br />
                            Combining AI with MetaTalan universe, will drive all layers of the metaverse: powering spacial computing,
                            supplying scaffoling to creators, and providing new and complex forms of storytelling. Thanks to AI advances
                            in natural language preprocessing, an old sci-fi dream become true: a virtual universe where AI-controlled
                            avatars can interact through many human expressions. Nlp is the most common application in metaverse since it
                            makes digital experience more realistic and immersive through conversations</p>

                        <h3>NLP in metaTalan</h3>
                        <p>Users are the heart in metaverse and the accuracy of those characters will decide the level of immersion and
                            credibility in digital experience in Metaverse. That’s why, Conversational ai avatar is exceptional since it
                            would enable the invention of AI-virtual character to populate immersive world. This will take metaverse to a
                            whole new level as conversational ai will play the most important role in creating sophisticated and naturally
                            acting avatars. </p>

                        <h4>Digital Human in metatalan</h4>
                        <p>In MetaTalan universe, the absence of a manager (leave, trip, meeting …) generates automatically the absence
                            of his avatar during the on boarding process. Hence the importance of AI-controlled characters. Ai-Based
                            avatars are associated with different managers who will accompany the new recruits to their DESK. Every
                            manager will have its personalized avatar that will imitate his way of speaking and his personality trait. As
                            collabs can interact in MetaTalan, communicate and trade with each other without any intermediary state, there
                            are no limits in metaverse and creativity. </p>

                        <h4>Multilingual in MetaTalan</h4>
                        <p>In addition to AI-controlled characters, universal translation is a fantastic power that people have wished
                            for. This basic toolset will eliminate language barriers and allow collabs from different nations to
                            communicate interact and exchange information in their parent language or favoured language in real time.
                            Combining global language translator with MetaTalan universe will create a completely new digital world where
                            information is easily accessed through breaking down language barriers.</p>

                        <h3>Approach adapted for MetaTalan Avatars</h3>
                        <p>In order to create AI-Controlled characters for MetaTalan universe, three possible approaches can be
                            followed: <br /></p>
                        <p>The creation of AI controlled Avatar for each manager require the identification of his personality trait
                            according to the five big factor model. The BF Model describes five different personalities ; extraversion,
                            agreeableness, openness, narcissism, consciousness. Those five traits reflects the difference between people
                            in terms of feelings, thoughts, behaviour in relatively consistent ways across time and situation.</p>
                        <p>Once trait is identified, AI model is finetuned on a dataset related to the identified trait to generate
                            general discussions. This dataset is enriched over time with discussions dealt in the MetaTalan universe.
                            After a specific number of QA, the AI model is re-finetuned. This approach aims to make the AI generative
                            model is evolutive over time.</p>
                        <p>In this second approach, there is no need to identify the manager’s personality trait. Since every time the
                            manager is connected to MetaTalan universe, he will interact with collabs and generate discussions. Every
                            conversation dealt will be saved to be later used for fine tuning the AI model. In this approach the AI
                            generative model is evolutive over time.</p>
                        <p>In this third approach, a dataset is created for every manager. This dataset is composed of different tags
                            with their corresponding questions and specific answers. This approach aims to create a classNameification model
                            for predicting a predefined answer.</p>

                        <h3>Multilingual in MetaTalan</h3>
                        <p>In retrieval based approaches, ai-controlled characters use NLP to understand the user’s request. Adding a
                            self-feeding method to this approach, will enable avatars to get smarter, learn new patterns and be evolutive
                            over time. However, IN retrieval-based approach, responses are always predefinites. Hence, the importance of
                            generative approach where ai-character will generate non predefinite answers.</p>
                        <p>Dialogue generation is a fundamental component in virtual assistants in the digital world. It's a text
                            generation task from scratch in NLP with the goal of appearing indistinguishable to human written text. Text
                            generation models such as Bert, Gpt2, Dialog-gpt, GPT-J, GPT3 rely on transformers to generate sequences. All
                            of these models need a large dataset of QA to be performant and the presence of context in each QA pair
                            determine their accuracy. In our case, Dialogpt trained to generate discussions showed the best performance.
                        </p>

                    </section>
                </div>
            </main >
            <Footer />
        </div>
    );
};

export default Article1;

import React from 'react';
import './Footer.css'
import owniverseLogo from "../assets/img/owniverse_logo.png"
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="footer-info ">
                                <div className="logo">
                                    <img src={owniverseLogo} alt="logo"
                                        className="img-fluid footer-center" />
                                </div>
                                <p className="pb-3 footer-center">Own your metaverse</p>
                                <p>
                                    <strong>Phone:</strong> +216 70 015 065<br />
                                    <strong>Email:</strong> support@owniverse.org<br />
                                    <strong>Location:</strong> 10 Rue de l'énergie solaire Impasse N°1 Charguia 1, Tunis 2035<br />
                                </p>
                                <div className="social-links mt-3">
                                    <a target="_blank" href="https://talan.com/"><i className="bx bx-world"></i></a>
                                    <a target="_blank" href="https://twitter.com/owniverse_" className="twitter"><i
                                        className="bx bxl-twitter"></i></a>
                                    <a target="_blank" href="https://www.facebook.com/Owniverse-110332895021448" className="facebook"><i
                                        className="bx bxl-facebook"></i></a>
                                    <a target="_blank" href="https://www.instagram.com/owniverse_insta/" className="intagram"><i
                                        className="bx bxl-instagram"></i></a>
                                    <a target="_blank" href="https://www.linkedin.com/company/owniverse" className="linkedin"><i
                                        className="bx bxl-linkedin"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 footer-links">
                            <h4>Resources</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/about">About Owniverse</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/blog">Blog</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-6 footer-links">
                            <h4>Our Features</h4>

                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/features">Our features</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;


import React from 'react';
import metatalan from "../../assets/img/metatalan/logo-metatalan.svg"
import Loader from './Loader'
import "./LoadingScreen.css"



export default function LoadingScreen({ loadingPercentage }) {

    return (
        <div className="cont-l">
            <div className="frame-wrapper-l">
                <img src={metatalan} alt="metatalan-logo" />
                <div className='loader-bar'>
                    <Loader loadingPercentage={loadingPercentage} />
                </div>
                <div className="label">
                    <span className="text-wrapper-l">Metatalan</span>
                    <span className="span"> is the first onboarding experience in the metaverse.</span>
                </div>
            </div>
        </div>
    )
}

import React, { useEffect, useRef } from 'react';
import './Navbar.css'
import owniverse_logo from '../assets/img/owniverse_logo.png'
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
    const headerRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const navbar = document.querySelector('#navbar');
        const navbarToggle = document.querySelector('.mobile-nav-toggle');
        navbarToggle.addEventListener('click', (e) => {
            const navbar = document.querySelector('#navbar');
            navbar.classList.toggle('navbar-mobile');
            navbarToggle.classList.toggle('bi-list');
            navbarToggle.classList.toggle('bi-x');
        });

        const navbarDropdowns = document.querySelectorAll('.navbar .dropdown > a');
        navbarDropdowns.forEach((dropdown) => {
            dropdown.addEventListener('click', (e) => {
                if (navbar.classList.contains('navbar-mobile')) {
                    e.preventDefault();
                    dropdown.nextElementSibling.classList.toggle('dropdown-active');
                }
            });
        });

        const scrollToLinks = document.querySelectorAll('.scrollto');
        scrollToLinks.forEach((link) => {
            link.addEventListener('click', (e) => {
                const element = document.querySelector(link.hash);
                if (element) {
                    e.preventDefault();

                    if (navbar.classList.contains('navbar-mobile')) {
                        navbar.classList.remove('navbar-mobile');
                        navbarToggle.classList.toggle('bi-list');
                        navbarToggle.classList.toggle('bi-x');
                    }
                    window.scrollTo({ top: element.offsetTop });
                }
            });
        });

        return () => {
            navbarToggle.removeEventListener('click', () => { });
            navbarDropdowns.forEach((dropdown) => {
                dropdown.removeEventListener('click', () => { });
            });
            scrollToLinks.forEach((link) => {
                link.removeEventListener('click', () => { });
            });
        };
    }, []);

    useEffect(() => {
        const routes = ['/owniverse-own-your-metaverse', '/book-a-tour', '/contact', '/ai-and-the-metaverse', '/ai-and-the-metaverse', '/blockchain-in-the-metaverse', '/the-metaverse-an-upgrade-not-a-trend', '/thanks', '/404'];

        if (routes.includes(location.pathname)) {
            headerRef.current.classList.add('header-scrolled');
        }
        if (!routes.includes(location.pathname)) {
            headerRef.current.classList.remove('header-scrolled');
        }

        const scrollTo = (el) => {
            let offset = headerRef.current.offsetHeight;

            if (!headerRef.current.classList.contains('header-scrolled')) {
                offset -= 20;
            }

            let elementPos = document.querySelector(el).offsetTop;
            window.scrollTo({
                top: elementPos - offset,
                behavior: 'smooth',
            });
        };

        const headerScrolled = () => {
            try {
                if (window.scrollY > 100) {
                    headerRef.current.classList.add('header-scrolled');
                } else {
                    if (routes.indexOf(location.pathname) === -1)
                        headerRef.current.classList.remove('header-scrolled');
                }
            }
            catch { }
        };

        window.addEventListener('load', headerScrolled);
        window.addEventListener('scroll', headerScrolled);

        return () => {
            window.removeEventListener('load', headerScrolled);
            window.removeEventListener('scroll', headerScrolled);
        };
    }, [location]);

    return (
        <header
            id="header"
            className="fixed-top d-flex align-items-center header-transparent"
            ref={headerRef}
        >
            <div className="container d-flex align-items-center justify-content-between">
                <div className="logo">
                    <Link className="nav-link navbar-item" to="/">
                        <img src={owniverse_logo} alt="logo" className="img-fluid" />
                    </Link>
                </div>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li><Link className="nav-link navbar-item" to="/about">About us</Link></li>
                        <li><Link className="nav-link navbar-item" to="/our-offer">Our offer</Link></li>
                        <li><Link className="nav-link navbar-item" to="/our-solutions">Our solutions</Link></li>
                        <li><Link className="nav-link navbar-item" to="/gallery">Gallery</Link></li>
                        <li><Link className="nav-link navbar-item" to="/blog">Blog</Link></li>
                        <li><Link className="nav-link navbar-item" to="/contact">Contact us</Link></li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle" />
                </nav>
            </div>
        </header >
    );
};

export default Navbar;

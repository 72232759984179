import { AvatarCreator, AvatarCreatorConfig } from '@readyplayerme/react-avatar-creator';
import React from "react";

const config: AvatarCreatorConfig = {
  clearCache: true,
  bodyType: 'fullbody',
  quickStart: false,
  language: 'en',
};

const style = { width: '100%', height: '100vh', border: 'none' };

export default function RPM({ handleOnAvatarExported }) {
  return (
    <>
      <AvatarCreator subdomain="metatalan" config={config} style={style} onAvatarExported={handleOnAvatarExported} />
    </>
  );
}
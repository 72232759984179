import React, { useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import './Owniverse.css'
import Loader from "./Loader";


function Insum({ onDisconnect }) {

    const { unityProvider, isLoaded, loadingProgression, UNSAFE__unityInstance, requestFullscreen, unload } = useUnityContext({
        loaderUrl: "insumBuild/insum.loader.js",
        dataUrl: "insumBuild/insum.data",
        frameworkUrl: "insumBuild/insum.framework.js",
        codeUrl: "insumBuild/insum.wasm",
    });

    const loadingPercentage = Math.round(loadingProgression * 100);

    useEffect(() => {
        window.unityInstance = UNSAFE__unityInstance;
    }, [UNSAFE__unityInstance]);

    function handleClickEnterFullscreen() {
        requestFullscreen(true);
    }

    async function disconnect() {
        await unload();
        onDisconnect()
    }
    return (
        <>
            <div>
                {isLoaded === false && (
                    <Loader loadingPercentage={loadingPercentage} />
                )}
                <Unity unityProvider={unityProvider}
                    className="my-unity-app"
                />

            </div>
            {isLoaded ?
                (<>
                    <i className="bi bi-arrows-fullscreen full-scrn" onClick={handleClickEnterFullscreen}></i>
                    <i className="bi bi-box-arrow-in-left disconnect" onClick={disconnect}></i>
                </>)
                : <></>
            }
        </>
    )

}

export default Insum
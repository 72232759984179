import React from 'react';
import "./ArticleCard.css"
import {
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCol,
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
const ArticleCard = (props) => {
    const { title, description, image, link, tag } = props
    return (
        <>
            <MDBCol data-aos="fade-right" style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <MDBCard style={{ minHeight: "550px" }}>
                    <MDBCardImage
                        src={image}
                        alt='...'
                        position='top'
                    />
                    <MDBCardBody>
                        <MDBCardTitle>{title}</MDBCardTitle>
                        <MDBCardText className='category'>{tag}</MDBCardText>
                        <MDBCardText className='video-card-text mb-5'>
                            {description}
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
                <Link to={link} className="yo">Read more</Link>
            </MDBCol>
        </>
    );
};

export default ArticleCard;

import React, { useEffect } from 'react';
import OurValues from './OurValues';
import Team from './Team';
import WhoAreWe from './WhoAreWe'
import ContactUs from './ContactUs'
import Navbar from '../Navbar';
import Footer from '../Footer';

import HeroAbout from './HeroAbout';

const AboutPage = () => {

    useEffect(() => {
        document.title = 'Owniverse - Get to know us';
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Navbar />
            {/* <HeroMain
                title="Owniverse Adventure"
                description="Build your custom metaverse with owniverse's rich set of features."
                buttonText="Get to know us"
                buttonHref="#whoarewe"
            /> */}
            <HeroAbout />
            <WhoAreWe />
            <OurValues />
            <Team />
            <ContactUs />
            <Footer />
        </div>
    );
};

export default AboutPage;
import React, { useEffect } from 'react';
import trend1 from "../../assets/img/blog/trend_1.webp"
import trend2 from "../../assets/img/blog/trend_2.webp"
import trend3 from "../../assets/img/blog/trend_3.webp"
import trend4 from "../../assets/img/blog/trend_4.webp"
import trend5 from "../../assets/img/blog/trend_5.webp"
import sinda from "../../assets/img/team/sinda.webp"
import metaverse from "../../assets/img/blog/metaverse.jpg"
import Navbar from '../Navbar';
import Footer from '../Footer';
import './Articles.css'

const Article2 = () => {
    useEffect(() => {
        document.title = 'Owniverse - Blog';
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Navbar />
            <div class="article-header">
                <h1>The metaverse, an upgrade not a trend</h1><br />
                <img src={metaverse} alt="metaverse" />
            </div>
            <main id="main-article">
                <div className="flex-child author">
                    <img src={sinda} />
                    <p>Sinda Besrour</p>
                    <p className="bio">AI consultant</p>
                    {/* <p>share on:
                        <a href="#" target="popup"
                            onclick="window.open('https://linkedin.com/sharing/share-offsite/?url=https://owniverse.org/blog/upgrade-not-a-trend.html','upgrade-not-a-trend','width=600,height=400')" />
                        <i className="bi-linkedin"></i>
                        <a href="#" target="popup"
                            onclick="window.open('https://twitter.com/share?url=https://owniverse.org/blog/upgrade-not-a-trend.html&text=The+metaverse,+an+upgrade+not+a+trend&hashtags=owniverse','upgrade-not-a-trend','width=600,height=400')">
                            <i className="bi bi-twitter"></i>
                        </a>
                    </p> */}
                </div>

                <div className="flex-child">
                    <section className="article">

                        <h3>Introduction</h3>
                        <p>Metaverse! The booming concept echoing across the globe, the revolution of the decade, the hype of the 21st
                            century, the future of the Internet, the demise of traditional technology…</p>
                        <p>Such are the words uttered by famous tech experts and ambitious businessmen. But is it truly as grand as
                            people describe it?
                            Indeed, the human brain is prone to emphasize, exaggerate, and even get compelled once amazed. As such,
                            through this article, we want to convey the greatness of the metaverse while invoking critical thinking.
                            But before proceeding, let’s take a glance at the definition of the metaverse: what is it exactly? </p>

                        <h3>The metaverse from a philosophical and technical point of view</h3>
                        <p>In ancient Greek, the word “meta” means beyond, so the metaverse implies a parallel dimension or a
                            transcendence that overlays the physical world.<br />
                            Enough of the philosophy!! Let’s talk tech!! <br />
                            Technically speaking, the metaverse is an abstract concept that exploits XR technologies to make the user
                            experience more immersive. But first, what is XR? </p>

                        <h3>XR Technologies</h3>
                        <p>XR (Extended Reality) is a general concept that englobes 3 revolutionary technologies: AR, VR, and MR. </p>
                        <img src={trend1} alt="" />
                        <p>AR (Augmented Reality) is the overlay of virtual objects on the physical world through a screen device such
                            as smartphones, tablets, computers… An example of AR is the snapshot mobile app.
                            On the other hand, VR (Virtual Reality) offers a fully immersive experience in a digital world thanks to the
                            VR headsets. Mainly, there are 3 types of VR headsets: Tethered ones which are linked to a screen device
                            through a cable, smartphone VRs which are designed to hold a smartphone on-front, and standalone VRs which
                            function independently as they have their own OS (Operating System).
                            Finally, the most intriguing of all is MR or Mixed Reality. It is a technology that emerged with the
                            introduction of the metaverse. It is the combination of both AR and VR, more specifically, the overlay of
                            interactive digital objects in the physical world using MR headsets. </p>

                        <h3>Metaverse: From trendy to fundamental </h3>
                        <p>Throughout the past few years, XR technologies were exclusively exploited for entertainment and distraction
                            purposes. Indeed, people have been engaging in immersive games with all their senses.</p>
                        <img src={trend2} alt="" />
                        <p>But the metaverse is much more than just a simple game. It enables people to carry out vital tasks such as
                            work and education more effectively and more immersively. </p>
                        <p>However, some people might be wondering about the added value of the metaverse compared to the current hype:
                            The famous Internet. </p>
                        <p>As it is, many think that the internet is more than enough to handle day-to-day activities, and the metaverse
                            is just about enhancing the user experience, making it a luxury. </p>
                        <p>Well, not quite so! </p>
                        <p>The metaverse isn't only about offering a fully immersive experience for show. It enables people to exploit
                            their resources to the fullest regardless of the spacetime frame and the circumstances. A solid proof to this
                            argument is Covid-19. No one can deny the impact of the pandemic on national economies due to remote
                            interaction. Countless sectors were affected by this new doctrine. Perhaps, the most damaged one is education.
                            Indeed, the quality of the learning content has dropped considerably even with the assistance of IT solutions.
                            The internet has offered a pseudo-satisfying experience but couldn't surmount the spatial obstacle. Naturally,
                            people learn with their senses, especially their sight. They need to be put in the context in order to
                            concentrate. That context was out of reach since existing communication technologies lacked the immersive
                            part.</p>
                        <p>On the other hand, the metaverse is not bounded by space. Students will learn as if they are really in className.
                            All connection with any sort of distraction will be severed. </p>
                        <p>Apart from the immersive learning experience, the metaverse will also upgrade the educational content as
                            learners will touch, expand, shrink and step in abstract and unimaginable subjects such as mathematics and
                            physics, which is not possible with current technologies powered by the internet. Consequently, the
                            information will be tangible and much easier to digest. </p>
                        <img src={trend3} alt="" />
                        <p>The same goes for professional education. Indeed, workers will be able to upgrade their soft and hard skills
                            to a whole new level by simulating real-world situations. </p>
                        <p>Another crucial domain that will be revamped with the introduction of the metaverse is medicine. Human life
                            is invaluable, so doctors and medical crews need to be provided with the best resources in order to fulfill
                            their jobs flawlessly. As it is, there are already implemented use cases in healthcare. For instance, surgeons
                            were able to make surgical debriefing by visualizing the 3D anatomy of a patient and performing a
                            pseudo-surgery. This step resulted in much higher surgical accuracy and tighter timeframes. </p>
                        <img src={trend4} alt="" />
                        <p>Moreover, psychiatrics were able to cure Post Traumatic Stress (PTS), and medical apprentices were able to
                            simulate real-life training in a risk-free way. </p>
                        <p>Dear reader, if you’re not convinced, then we are not done yet! </p>
                        <p>Industry! The pillar of economy will-without a doubt-be integrated in the metaverse. </p>
                        <p>You may be wondering: How so? </p>
                        <p>Two crucial steps in most industries are prototyping and maintenance. Thanks to XR technologies these phases
                            will be carried out more efficiently and economically in terms of time and money. For instance, experts can
                            design digital twins of physical prototypes with accurate details, touch, expand, shrink and walk through them
                            to detect anomalies and shortcomings easily, which will result in framing the project scope. The same goes for
                            maintenance. </p>
                        <img src={trend5} alt="" />
                        <p>Until this point, we have been talking about the metaverse with XR technologies as its basis. </p>
                        <p>Well, that is definitely true, but not enough. Other technologies such as AI and Blockchain could be
                            integrated in the metaverse. For instance, you could talk to someone through his avatar but still see his
                            motor reactions and facial expressions in real-time. That could be very useful when conducting job interviews,
                            making business deals or even just exchanging knowledge. You could also carry out transactions in the
                            metaverse, exchange currencies, and even buy NFTs (Non-Fungible Token). </p>
                        <p>Enough with arguments!! Human potential is boundless, so why restrain it? </p>

                        <h3>The Evolution of Mankind </h3>
                        <p>Since the time of creation, human beings haven’t stopped evolving. They identified their vital needs and
                            adapted themselves to their environment. When winter came for the first time, they invented clothes. Time
                            passed and humans made the ultimate discovery: Fire. Consequently, their lives changed radically: they were
                            able to travel at nighttime, repel predators, warm themselves and even cook. </p>
                        <p>As time went by, they found ways to communicate. They started with signs, then developed languages. That
                            said, they were able to farm and trade goods. </p>
                        <p>Many centuries later, humans excelled in exact sciences and revolutionized the industrial sector giving birth
                            to a new era: The era of internet. </p>
                        <p>The metaverse is a new vision of the internet and a different perception of human co-experience. It gives us
                            the possibility to interact with each other while preserving our integrity. Misconduct such as harassment and
                            violence are irrelevant in the metaverse since there is no physical contact… </p>
                        <p>From a different perspective, you can think of the time when smartphones started to emerge. At first, some
                            people labeled them as unrealistic, and others considered them a luxury. Nowadays, smartphones are vital.
                            Identically, the metaverse is in its first phase. You could think of it as a fetus waiting to be nurtured.
                        </p>
                        <p>It is too early, even meaningless to review and assess the metaverse. It will follow the direction that we
                            trace for it. It is up to us to imagine and work on concretizing useful and impactful solutions that disrupt
                            the limitations and fears of our real world while creating richness and job opportunities. </p>
                        <p>That is our drive at Talan innovation factory. Our motto is dream and make the impossible possible. </p>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Article2;

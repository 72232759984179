import React from 'react';
import './Faq.css'
import { Link } from 'react-router-dom'

const Faq = () => {
    return (
        <section id="faq" className="faq section-bg">
            <div className='container'>
                <div className="section-title" data-aos="fade-up">
                    <h2>F.A.Q</h2>
                    <p>Frequently Asked Questions</p>
                </div>

                <div className="faq-list">
                    <ul>
                        <li data-aos="fade-up">
                            <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-1"
                                className="collapsed">What is the metaverse? <i className="bx bx-chevron-down icon-show"></i><i
                                    className="bx bx-chevron-up icon-close"></i></a>
                            <div id="faq-list-1" className="collapse" data-bs-parent=".faq-list">
                                <p>
                                    the metaverse is a graphically rich virtual space where people can work, play, shop, socialize. The
                                    metaverse blurs the line between your interactions online and in real life. </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="100">
                            <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2"
                                className="collapsed">What can I do in the metaverse? <i className="bx bx-chevron-down icon-show"></i><i
                                    className="bx bx-chevron-up icon-close"></i></a>
                            <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                                <p>
                                    Buy and sell “real estate” and cryptocurrencies, virtually “travel the world”, take classes, dating,
                                    friendship, education or business.. all will take place in the metaverse.
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="200">
                            <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3"
                                className="collapsed">Will the metaverse have NFTs? <i className="bx bx-chevron-down icon-show"></i><i
                                    className="bx bx-chevron-up icon-close"></i></a>
                            <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                                <p>
                                    In a decentralized metaverse, users will be able to mint assets on blockchains as NFTs.
                                </p>
                            </div>
                        </li>


                        <li data-aos="fade-up" data-aos-delay="400">
                            <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5"
                                className="collapsed">Can I customize my own avatar? <i className="bx bx-chevron-down icon-show"></i><i
                                    className="bx bx-chevron-up icon-close"></i></a>
                            <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                                <p>
                                    Yes. you can easily create your own avatar with ReadyPlayerMe and integrate it with Owniverse.
                                </p>
                            </div>
                        </li>

                    </ul>

                </div>
                <div className="text-center" data-aos="fade-up" data-aos-delay="400">
                    <Link to="contact" className="submit">Contact us</Link>
                </div>
            </div>
        </section>
    );
};

export default Faq;
import React from 'react';
import ReactTypingEffect from 'react-typing-effect';

const Typing = () => {
    return (
        <>
            <ReactTypingEffect
                text={["Work.", "Learn.", "Meet.", "Communicate.", "Collaborate.", "Celebrate.", "Socialize."]}
                speed="100"
                typingDelay="500"
                eraseSpeed="100"
                eraseDelay="1500"
                cursorRenderer={cursor => <h1>{cursor}</h1>}
                displayTextRenderer={(text, i) => {
                    return (
                        <h1>
                            {text.split('').map((char, i) => {
                                const key = `${i}`;
                                return (
                                    <span
                                        key={key}
                                    >{char}</span>
                                );
                            })}
                        </h1>
                    );
                }}
            />
        </>
    );
};

export default Typing
import React, { useEffect } from 'react';
import Features from './Features';
import Navbar from '../Navbar';
import Footer from '../Footer';
import HeroMain from '../HeroMain';

const SolutionPage = () => {
    useEffect(() => {
        document.title = 'Owniverse - Features';
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Navbar />
            <HeroMain
                title="Owniverse Adventure"
                description="Build your custom metaverse with owniverse's rich set of features."
                buttonText="Explore more"
                buttonHref="#details"
            />
            <Features />
            <Footer />
        </div>


    );
};

export default SolutionPage;
import React from 'react';
import './Features.css'
import Multiplayer from "../../assets/videos/Multiplayer.mp4"
import GroupCall from "../../assets/videos/GroupCall.mp4"
import Privatemeeting from "../../assets/videos/Privatemeeting.mp4"
import Screensharing from "../../assets/videos/Screensharing.mp4"
import Physicalinteraction from "../../assets/videos/Physicalinteraction.mp4"
import Guidebot from "../../assets/videos/Guidebot.mp4"

const Features = () => {
    return (
        <section id="details" className="details">
            <div className='container'>
                <div className="section-title" data-aos="fade-up">
                    <h2>Features</h2>
                    <p>Check The Features</p>
                </div>

                <div className="row content feature">
                    <div className="col-6 gif" data-aos="fade-right">
                        <video muted autoPlay loop className="img-fluid rounded-5">
                            <source src={Multiplayer} type="video/mp4" />
                        </video>
                    </div>
                    <div className="col-2 pt-4 description" data-aos="fade-up">
                        <h3>Multiplayer</h3>
                        <p>
                            Share your metaverse with your friends and enjoy fluid multiplayer experience.
                        </p>
                    </div>
                </div>

                <div className="row content feature">
                    <div className="col-6 order-1 order-md-2 gif" data-aos="fade-left">
                        <video muted autoPlay loop className="img-fluid rounded-5">
                            <source src={GroupCall} type="video/mp4" />
                        </video>
                    </div>
                    <div className="col-2 pt-5 order-2 order-md-1 description" data-aos="fade-up">
                        <h3>Video and text chat</h3>
                        <p>
                            Say goodbye to boring out of touch meetings and seamlessly interact with your co-workers and friends in a
                            fun and fluid manner using either text, voice or video chat.
                        </p>

                    </div>
                </div>

                <div className="row content feature">
                    <div className="col-6 gif" data-aos="fade-right">
                        <video muted autoPlay loop className="img-fluid rounded-5">
                            <source src={Privatemeeting} type="video/mp4" />
                        </video>
                    </div>
                    <div className="col-2 pt-5 description" data-aos="fade-up">
                        <h3>Private Meetings</h3>
                        <p>Need some privacy? Create your own private rooms and invite people to join you.</p>
                    </div>
                </div>

                <div className="row content feature">
                    <div className="col-6 order-1 order-md-2 gif" data-aos="fade-left">
                        <video muted autoPlay loop className="img-fluid rounded-5">
                            <source src={Screensharing} type="video/mp4" />
                        </video>
                    </div>
                    <div className="col-2 pt-5 order-2 order-md-1 description" data-aos="fade-up">
                        <h3>Screen sharing</h3>
                        <p>
                            Presentations were never this fun before. Multiple people can choose to share their screens at the same
                            time, thus, collaboration becomes much more simple and easy.
                        </p>
                    </div>
                </div>

                <div className="row content feature">
                    <div className="col-6 gif" data-aos="fade-right">
                        <video muted autoPlay loop className="img-fluid rounded-5">
                            <source src={Physicalinteraction} type="video/mp4" />
                        </video>
                    </div>
                    <div className="col-2 pt-5 description" data-aos="fade-up">
                        <h3>Interactions </h3>
                        <p>
                            With a diverse emote system, give life to your avatar with fluid animations and express your approval, your
                            enjoyment or even dance.
                        </p>
                    </div>
                </div>

                <div className="row content feature">
                    <div className="col-6 order-1 order-md-2 gif" data-aos="fade-left">
                        <video muted autoPlay loop className="img-fluid rounded-5">
                            <source src={Guidebot} type="video/mp4" />
                        </video>
                    </div>
                    <div className="col-2 pt-5 order-2 order-md-1 description" data-aos="fade-up">
                        <h3>Guide Bot</h3>
                        <p>
                            Afraid of being lost in the metaverse? fear no more, as our guide bot will escort you around, just follow
                            its steps!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Features;


import React from 'react';
import './Upcoming.css'
import ai from '../../assets/img/upcoming/ai.webp'
import blockchain from '../../assets/img/upcoming/blockchain.webp'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

const Upcoming = () => {
    return (
        <section id="upcoming" className="upcoming">
            <div className='container'>
                <Swiper
                    slidesPerView={1}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    modules={[Pagination, Navigation, Autoplay]}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={true}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <img src={ai} className="testimonial-img" alt="ai" />
                            <h3>Realtime transcription</h3>
                            <h4>Coming soon</h4>
                            <p>
                                Having problems hearing conversations in noisy environments? Our speech to text solution will display
                                realtime transcription for you.
                            </p>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <img src={ai} className="testimonial-img" alt="ai" />
                            <h3>Quick keyword search</h3>
                            <h4>Coming soon</h4>
                            <p>
                                Someone saying some words you don't understand? Get quick information about keywords without quitting
                                Owniverse.
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <img src={blockchain} className="testimonial-img" alt="blockchain" />
                            <h3>DAO</h3>
                            <h4>Coming soon</h4>
                            <p>
                                Take part in the decision-making process in a fully autonomous and transparent way.
                            </p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section >
    );
};

export default Upcoming;
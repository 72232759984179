import React, { useState } from 'react';
import axios from 'axios';
import './Form.css'
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';

const Form = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    let navigate = useNavigate();

    function handleVerify(token) {
        // Update the state of the component with the token returned by the reCAPTCHA service
        setCaptcha(token);
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (!captcha) {
            setErrorMessage("Captcha invalid")
            return
        }
        const obj = {
            firstname: firstName,
            lastname: lastName,
            email: email,
            subject: subject,
            message: message,
            captcha: captcha
        };
        setIsLoading(true);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_PATH}/contact.php`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: obj
        })
            .then(result => {
                if (result.status === 200) {
                    setIsLoading(false);
                    navigate("/thanks");
                }
            })
            .catch(error => { });
    };

    return (
        <form role="form" id="contact-form" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6 form-group">
                    <input className="form-control form-input" type="text" name="firstname" id="firstname"
                        placeholder="First Name" pattern="([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}" required
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                    />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input className="form-control form-input" type="text" placeholder="Last name" name="lastname"
                        id="lastname" pattern="([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}" required
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group mt-3">
                    <input className="form-control form-input" type="text" placeholder="Your Email" name="email" id="email"
                        pattern="(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}"
                        required
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group mt-3">
                    <input className="form-control form-input" type="text" placeholder="Subject" name="subject" id="subject"
                        required
                        value={subject}
                        onChange={(event) => setSubject(event.target.value)}
                    />
                </div>
            </div>
            <div className="form-group mt-3">
                <textarea
                    className="form-control form-input"
                    type="text"
                    placeholder="what would you like to learn ?"
                    rows="5"
                    name="message"
                    required
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                ></textarea>
            </div>
            <div className="form-group mt-3">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={handleVerify}
                />
                <p style={{ color: "#FF0000" }}>{errorMessage}</p>
            </div>
            <div className="text-center">
                {isLoading ?
                    (
                        <div className="submit" type="submit" name="submit" value="Submit">
                            {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                        </div>
                    ) :
                    <input className="submit" type="submit" name="submit" value="Submit" />
                }

            </div>
        </form>
    );
};

export default Form;
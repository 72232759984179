import React, { useState } from 'react';
import {
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import './VideoCard.css';
import FsLightbox from "fslightbox-react";
import heroin from "../../assets/img/solutions/hero-in.png"
import metatalan from "../../assets/img/solutions/metatalan.png"
import kitchen from "../../assets/img/solutions/kitchen.png"
import mcdonads from "../../assets/img/solutions/mcdonads.png"
import perfume from "../../assets/img/solutions/perfume.png"
import tour from "../../assets/img/solutions/tour.png"
import shadow from "../../assets/img/solutions/shadow.png"


const VideoCard = () => {

    const [toggler, setToggler] = useState(false);
    const [source, setSource] = useState('');

    return (
        <section>
            <FsLightbox
                sources={[source]}
                toggler={toggler}
            />
            <div className='container'>
                <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
                    <MDBCol data-aos="fade-right" className='c-col' style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                        <MDBCard className='b-card'>
                            <MDBCardImage
                                src={metatalan}
                                alt='...'
                                position='top'
                            />
                            <MDBCardBody >
                                <MDBCardTitle>Meta Talan</MDBCardTitle>
                                <MDBCardText className='category'>Collaboration</MDBCardText>
                                <MDBCardText className='video-card-text mb-5'>
                                    MetaTalan is a platform built for employees to interact with each other.  In MetaTalan you can hold conferences, schedule and participate in meetings, socialize with other collaborators, and much more.<br /><br /><br />
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                        <button className="yo" onClick={() => { setToggler(!toggler); setSource("https://www.youtube.com/watch?v=0MfS2kgSUcQ") }}>Watch video</button>
                    </MDBCol>


                    <MDBCol data-aos="fade-left" className='c-col' style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                        <MDBCard className='b-card'>
                            <MDBCardImage
                                src={perfume}
                                alt='...'
                                position='top'
                            />
                            <MDBCardBody>
                                <MDBCardTitle>Perfumer</MDBCardTitle>
                                <MDBCardText className='category'>Luxury</MDBCardText>
                                <MDBCardText className='video-card-text mb-5'>
                                    An immersive perfume portal and  toolbox for luxury products, enhanced with olfactory powers, which purpose is to upgrade the customer experience in free shops and beauty stores.  <br /><br /><br />
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                        <button className="yo" onClick={() => { setToggler(!toggler); setSource("https://www.youtube.com/watch?v=ErXk9hR3YPI"); }}>Watch video</button>
                    </MDBCol>

                    <MDBCol data-aos="fade-left" className='c-col' style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                        <MDBCard className='b-card'>
                            <MDBCardImage
                                src={shadow}
                                alt='...'
                                position='top'
                            />
                            <MDBCardBody>
                                <MDBCardTitle>The Shadow</MDBCardTitle>
                                <MDBCardText className='category'>Wealth management</MDBCardText>
                                <MDBCardText className='video-card-text mb-5'>
                                    Empower the RM (Relationship Manager) to harness  Augmented Reality super capabilities for a top-notch proximity customer advisory, through helping the customer to better visualize, perceive and manipulate its most precious asset "Data".<br />
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>

                        <button className="yo" onClick={() => { setToggler(!toggler); setSource("https://www.youtube.com/watch?v=xOvhomJOIaU"); }}>Watch video</button>
                    </MDBCol>
                    <MDBCol data-aos="fade-down" className='c-col' style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                        <MDBCard className='b-card'>
                            <MDBCardImage
                                src={heroin}
                                alt='...'
                                position='top'
                            />
                            <MDBCardBody>
                                <MDBCardTitle>Hero-IN</MDBCardTitle>
                                <MDBCardText className='category'>Sports</MDBCardText>
                                <MDBCardText className='video-card-text mb-5'>
                                    A Sports Complex Metaverse where anyone can buy and sell a piece of land  consisting of multi-purpose Areas:
                                    an Arena, a Store, a museum and ticketing (Buy tickets for real matches inside the metaverse).<br /><br /><br />
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                        <button className="yo" onClick={() => { setToggler(!toggler); setSource("https://www.youtube.com/watch?v=SJUU1wHP1yo"); }}>Watch video</button>
                    </MDBCol>

                    <MDBCol data-aos="fade-right" className='c-col' style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                        <MDBCard className='b-card'>
                            <MDBCardImage
                                src={kitchen}
                                alt='...'
                                position='top'
                            />
                            <MDBCardBody>
                                <MDBCardTitle>Kitchen realtime interactions</MDBCardTitle>
                                <MDBCardText className='category'>Real Estate</MDBCardText>
                                <MDBCardText className='video-card-text mb-5'>
                                    An immersive experience where you can fully customize and interact with the different scene elements (lights, hob, tap, door, Wall colors, etc.).
                                    You can move, touch, and feel as if you were actually there.
                                    Preserving the highest quality.<br /><br />

                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                        <button className="yo" onClick={() => { setToggler(!toggler); setSource("https://www.youtube.com/watch?v=UOyIgWhp5ws"); }}>Watch video</button>
                    </MDBCol>

                    <MDBCol data-aos="fade-up" className='c-col' style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                        <MDBCard className='b-card'>
                            <MDBCardImage
                                src={mcdonads}
                                alt='...'
                                position='top'
                            />
                            <MDBCardBody>
                                <MDBCardTitle>McDonald's Metaverse Prototype</MDBCardTitle>
                                <MDBCardText className='category'>Delivery</MDBCardText>
                                <MDBCardText className='video-card-text mb-5'>
                                    McDonald's brings people together and makes their meals taste better even if they are physically separated.
                                    You can order your meal in the metaverse and receive it both virtually and physically.<br /><br />
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                        <button className="yo" onClick={() => { setToggler(!toggler); setSource("https://www.youtube.com/watch?v=1A8kfbIAlfA"); }}>Watch video</button>
                    </MDBCol>

                    <MDBCol data-aos="fade-left" className='c-col' style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                        <MDBCard className='b-card'>
                            <MDBCardImage
                                src={tour}
                                alt='...'
                                position='top'
                            />
                            <MDBCardBody>
                                <MDBCardTitle>Real estate guided tour - REAVR</MDBCardTitle>
                                <MDBCardText className='category'>Real Estate</MDBCardText>
                                <MDBCardText className='video-card-text mb-5'>
                                    Agent or customer,  you signed up ​for a multiplayer experience with​ customizable features that allow​ you to adapt your virtual tour ​perfectly to the desired design​ and interact with the environment.<br /><br /><br />
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                        <button className="yo" onClick={() => { setToggler(!toggler); setSource("https://www.youtube.com/watch?v=t5FCbqbm_sY"); }}>Watch video</button>
                    </MDBCol>

                </MDBRow>
            </div>
        </section>
    );
};

export default VideoCard;

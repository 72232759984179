import React from 'react';
import './Team.css';
import Member from './Member';
import imen from "../../assets/img/team/imen.webp"
import racha from "../../assets/img/team/racha.webp"
import ezzeddine from "../../assets/img/team/ezzeddine.webp"
import sinda from "../../assets/img/team/sinda.webp"
import taher from "../../assets/img/team/taher.webp"
import ramzi from "../../assets/img/team/ramzi.webp"
import hamza from "../../assets/img/team/hamza.webp"
import anis from "../../assets/img/team/anis.webp"
import hichem from "../../assets/img/team/hichem.webp"
import fsili from "../../assets/img/team/fsili.webp"
import houssem from "../../assets/img/team/houssem.webp"

const Team = () => {
    return (
        <section>
            <div className='container'>
                <div className="section-title" data-aos="fade-up">
                    <h2>Team</h2>
                    <p>Meet our Team</p>
                </div>
                <div className="row" >
                    <Member image={imen} name="Imen" pseudo="The catalyst" data-aos="fade-left" />
                    <Member image={houssem} name="Houssem" pseudo="The dreamer" data-aos="fade-up" />
                    <Member image={anis} name="Anis" pseudo="The artist" data-aos="fade-left" />
                    <Member image={hamza} name="Hamza" pseudo="The warrior" data-aos="fade-up" />
                    <Member image={ezzeddine} name="Ezzeddine" pseudo="The geek" data-aos="fade-left" />
                    <Member image={fsili} name="Mohamed" pseudo="The sculptor" data-aos="fade-up" />
                </div>
                <div className="row" data-aos="fade-right">
                    <Member image={hichem} name="Hichem" pseudo="The machinist" data-aos="fade-left" />
                    <Member image={racha} name="Racha" pseudo="The scientist" data-aos="fade-up" />
                    <Member image={taher} name="Mohamed Taher" pseudo="The architect" data-aos="fade-left" />
                    <Member image={ramzi} name="Ramzi" pseudo="The savior" data-aos="fade-up" />
                    <Member image={sinda} name="Sinda" pseudo="The all purpose" data-aos="fade-up" />
                </div>
            </div>
        </section>
    );
};

export default Team;
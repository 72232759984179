import "./ErrorBox.css"

export default function ErrorBox({ title, message, onClose }) {
    return (
        <>
            <div className="modal-backdrop"></div>
            <div className="error-box">
                <div className="error-header">
                    <h2>{title}</h2>

                </div>
                <p className="error-message">{message}</p>
                <button className="close-button" onClick={onClose}>
                    Close
                </button>
            </div>
        </>
    )
}
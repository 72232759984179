import React from 'react';
import './Features.css'
import Feature from './Feature';
import { Link } from "react-router-dom";

const Features = () => {
    return (
        <section className="features">
            <div className='container'>
                <div className="section-title" data-aos="fade-up">
                    <h2>Features</h2>
                    <p>Check The Features</p>
                </div>

                <div className="row justify-content-center" data-aos="fade-left">

                    <Feature
                        title="Multiplayer"
                        icon="bi bi-people"
                        color="#ffbb2c"
                    />
                    <Feature
                        title="Global text chat"
                        icon="bi bi-chat-dots"
                        color="#5578ff"
                    />
                    <Feature
                        title="Private text chat"
                        icon="bi bi-incognito"
                        color="#e80368"
                    />

                    <Feature
                        title="Voice chat"
                        icon="bi bi-soundwave"
                        color="#e361ff"
                    />

                </div>
                <div className="row justify-content-center" data-aos="fade-left">

                    <Feature
                        title="Video chat"
                        icon="bi bi-camera-video"
                        color="#47aeff"
                    />

                    <Feature
                        title="Screen sharing"
                        icon="bi bi-window-fullscreen"
                        color="#ffa76e"
                    />

                    <Feature
                        title="Guide bot"
                        icon="bi bi-robot"
                        color="#11dbcf"
                    />
                </div>
                <div className="row justify-content-center" data-aos="fade-left">

                    <Feature
                        title="Private meeting"
                        icon="bi bi-person-video"
                        color="#b20969"
                    />

                    <Feature
                        title="Role management"
                        icon="bi bi-card-list"
                        color="#b2904f"
                    />

                </div>
                <div className="row justify-content-center" data-aos="fade-left">
                    <Link to="features" className="btn btn-primary btn-features">Explore more Features</Link>
                </div>
            </div>
        </section>
    );
};

export default Features;

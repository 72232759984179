import React from 'react';
import './Member.css'

const Member = (props) => {
    const { image, name, pseudo } = props
    return (
        <div className="col-2">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <div className="pic"><img src={image} className="img-fluid" alt={name} /></div>
                <div className="member-info">
                    <h4>{name}</h4>
                    <span>{pseudo}</span>
                </div>
            </div>
        </div>
    );
};

export default Member;
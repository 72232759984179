import React from 'react'
import "./BrowserBox.css"
import desktopIcon from "../../assets/img/desktop-icon.png"
import backBtn from "../../assets/img/back.svg"
import { useState } from 'react';
import ConnectButton from '../ConnectButton/ConnectButton';
import MicrosoftLogin from "react-microsoft-login";

export default function BrowserBox({ focus, unfocus, handleLogin }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [shrink, setShrink] = useState(false);

    const handleCardClick = () => {
        if (!isExpanded) {
            focus()
            setShrink(false)
            setIsExpanded(true);

        }
    };
    const handleBackClick = () => {
        setShrink(true);
        setIsExpanded(false);
        unfocus()
    };

    return (
        <div className={`cardb ${isExpanded ? 'expanded' : ''} ${shrink ? 'shrink' : ''}`} onClick={handleCardClick}>
            {/* <div className="rectangle" /> */}
            <img className='desktop' alt="vr-headset" src={desktopIcon} />
            <img className='back-btn' alt="back" src={backBtn}
                onClick={handleBackClick}
            />
            <div className="text-wrapper-b">WEB</div>
            <div className="vr-desc">
                <p>Explore a lightning-fast journey with your web device </p>
            </div>
            <div className="d-vr">
                <p>Explore a lightning-fast journey with your web device</p>
            </div>
            <div className='connect-btn'>
                <MicrosoftLogin forceRedirectStrategy={false} clientId={process.env.REACT_APP_MICROSOFT_ID} authCallback={handleLogin} tenantUrl={process.env.REACT_APP_TENANT_URL} redirectUri={process.env.REACT_APP_REDIRECT_URI}
                    children={<ConnectButton />}
                />

            </div>
        </div>
    )
}

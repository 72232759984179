import React, { useEffect } from 'react';
import ahmed from "../../assets/img/blog/ahmed.png"
import blockchain from "../../assets/img/blog/blockchain-and-the-metaverse.webp"
import Navbar from '../Navbar';
import Footer from '../Footer';
import './Articles.css'

const Article3 = () => {
    useEffect(() => {
        document.title = 'Owniverse - Blog';
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Navbar />
            <div className="article-header">
                <h1>Blockchain in the Metaverse</h1><br />
                <img src={blockchain} alt="metaverse" />
            </div>
            <main id="main-article">
                <div className="flex-child author">
                    <div className="auth">
                        <img className="imgA1" src={ahmed} />
                    </div>
                    <p>Ahmed & Feriel</p>
                    <p className="bio">Blockchain consultant</p>
                    {/* <p>share on:
                        <a href="#" target="popup"
                            onclick="window.open('https://linkedin.com/sharing/share-offsite/?url=https://owniverse.org/blog/blockchain-in-the-metaverse.html',
                            'blockchain-and-the-metaverse','width=600,height=400')" />
                        <i className="bi-linkedin"></i>
                        <a href="#" target="popup"
                            onclick="window.open('https://twitter.com/share?url=https://owniverse.org/blog/blockchain-in-the-metaverse.html&text=blockchain+and+the+metaverse&hashtags=owniverse',
                            'blockchain-and-the-metaverse','width=600,height=400')">
                            <i className="bi bi-twitter"></i>
                        </a>
                    </p> */}
                </div>

                <div className="flex-child">

                    <section className="article">
                        <p>The Metaverse has been capturing a lot of public attention ever since Facebook’s rebranding to Meta. But this
                            concept is not new to us. </p>
                        <p>For years, science fiction movies, futuristic novels and comic books envisioned the idea of the Metaverse as
                            the successor to today’s internet. </p>
                        <p>In simple words, this concept was instilled in our imagination and somehow matured to the point where we
                            become quite familiar with it and can explain, at least, at a very high level what the Metaverse is. </p>
                        <p>A parallel, unlimited, immersive digital world where you can socialize, collaborate and play in ways beyond
                            imagination could be a very simple definition of the Metaverse. </p>
                        <p>When it comes to immersiveness and creation of 3D environments, the last few decades have been a success as
                            the Tech industry achieved a great deal of advancements that have propelled the world into being a unified one
                            with just a click. </p>
                        <p>Despite ambitious forecasts like this, uncertainty reigns over the dream of the Metaverse as the size of
                            virtual reality remains a concern. Having a limited virtual world is not a Metaverse, so how can we solve this
                            problem? </p>
                        <p>Having a multitude of virtual worlds that are not necessarily unlimited but connected to each other and
                            interoperable can create an unlimited universe. </p>
                        <p>We believe that such design should ideally be built on top of a decentralized infrastructure, so that new
                            world can emerge and users teleport from one world to another seamlessly with the same avatars and items.
                            Blockchain is a great fit for this use case, we can identify many applications for it but let's list a few:
                        </p>
                        <ul>
                            <li>
                                <p>NFTs: All items and goods can be represented by non-fungible tokens. NFTs then can be transferred and
                                    used cross virtual world while keeping its specific characteristics. </p>
                            </li>
                            <li>
                                <p>DID: In a true Metaverse with countless worlds, the user should have a unique identifier that represents
                                    them. This identity will not be managed or controlled by a single organization/world, but it will be
                                    decentralized, controlled by the user himself and accepted anywhere in the Metaverse. </p>
                            </li>
                            <li>
                                <p>DAO: In this limitless universe decentralized autonomous organization are required to provide a
                                    transparent and rule-based way to manage organizations without the need for centralized authority. </p>
                            </li>
                            <li>
                                <p>Cryptos: Of course, this is the perfect environment for cryptos to shine more and serve as peer-to-peer
                                    payment method. </p>
                            </li>
                        </ul>
                        <p>Undoubtedly, there are many differing visions of how the blockchain can be used in the Metaverse and we
                            pointed only to a few of them. </p>
                        <p>But the greatest advantage of using the Blockchain might be that the Metaverse will not be the property of
                            any organization but a public space where anyone can join and collaborate. </p>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Article3;

import React from 'react';
import './OurOffer.css'
import { MDBContainer } from "mdb-react-ui-kit";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
const OurOffer = () => {
    return (
        <section id="our-offer">
            <MDBContainer fluid className="py-5 container" >
                <div className="main-timeline-2 tour">
                    <div className="timeline-2 left-2" data-aos="fade-up">
                        <i class="fa fa-info"></i>
                        <MDBCard className='crd'>
                            <MDBCardBody className="p-4">
                                <h5>Demystification and acculturation</h5>
                                <p className="mt-2">
                                    <i className="bx bx-check"> </i>We lead workshops and coaching sessions on the why? What? And how? Of the metaverse.<br />
                                    <i className="bx bx-check"> </i>We deliver acculturation sessions on the concepts of metaverse and immersive worlds, their underlying technologies and their uses.<br />
                                    <i className="bx bx-check"> </i>We deliver market benchmarking and insights thanks to our internal business intelligence tool.<br />
                                    <i className="bx bx-check"> </i>We help you identify the best business model for your activity ( play to learn, play and earn, learn to earn, create to earn).
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                    <div className="timeline-2 right-2" data-aos="fade-up">
                        <i class="fa fa-lightbulb-o"></i>
                        <MDBCard className='crd'>
                            <MDBCardBody className="p-4">
                                <h5>Ideation sessions</h5>
                                <p className="mt-2">
                                    <i className="bx bx-check"> </i>We lead brainstorming sessions.<br />
                                    <i className="bx bx-check"> </i>We assist you in the identification of impactful and user-centered use cases.<br />
                                    <i className="bx bx-check"> </i>We help you prioritize the use cases.

                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                    <div className="timeline-2 left-2" data-aos="fade-up">
                        <i class="fa fa-search"></i>
                        <MDBCard className='crd'>
                            <MDBCardBody className="p-4">
                                <h5>Framing</h5>
                                <p className="mt-2">
                                    <i className="bx bx-check"> </i>We restore the outcome of the ideation phase.<br />
                                    <i className="bx bx-check"> </i>We define the scope of the project.<br />
                                    <i className="bx bx-check"> </i>We set the project requirements and the features agreed upon.<br />
                                    <i className="bx bx-check"> </i>We planify the different aspects of the project.
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                    <div className="timeline-2 right-2" data-aos="fade-up">
                        <i class="fa fa-cube"></i>
                        <MDBCard className='crd'>
                            <MDBCardBody className="p-4">
                                <h5>Proof of concept</h5>
                                <p className="mt-2">
                                    <i className="bx bx-check"> </i>We create the solution storyboard.<br />
                                    <i className="bx bx-check"> </i>We onboard the project team.<br />
                                    <i className="bx bx-check"> </i>We implement and test the POC.<br />
                                    <i className="bx bx-check"> </i>We collect and measure the users' feedbacks.
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                </div>
            </MDBContainer>
        </section>


    );
};

export default OurOffer;
import React from 'react';
import './WhoAreWe.css'

const WhoAreWe = () => {
    return (
        <section id='whoarewe'>
            <div className="container">
                <div className="row">
                    <div className="col-6" data-aos="fade-right">
                        <div className="section-title" data-aos="fade-up">
                            <h2>Get to know us</h2>
                            <p>Who are we ?</p>
                        </div>
                        <div className='box-sh tour' data-aos="fade-up">
                            <p className="section-text" >
                                <i className="bx bx-check"> </i>We are a group of enthusiastic designers, graphic artists,  engineers and  researchers, passionate about
                                innovative technologies, namely, AI, Blockchain, XR, and the metaverse. <br />
                                <i className="bx bx-check"></i>Owniverse was originally part of Innovation Factory, the R&D department of Talan Tunisia.<br />
                                <i className="bx bx-check"></i>Nowadays, it is an independent entity that offers its clients the best virtual experience.</p>
                        </div>
                    </div>
                    <div className="col-6" data-aos="fade-right">
                        <div className="section-title" data-aos="fade-up">
                            <h2>Get to know us</h2>
                            <p>Our Vision</p>
                        </div>
                        <div className='box-sh tour' data-aos="fade-up">
                            <p className="section-text " >
                                <i className="bx bx-check"> </i>We are engaged to empower  a humanistic and responsible practice of technology that will make a new digital age, an era of progress for all. <br />
                                <i className="bx bx-check"> </i>We believe that the metaverse has the power to bring people together and we are dedicated to creating an inclusive meta-existence. <br />
                                <i className="bx bx-check"> </i>We envision a metaverse that is not only a place of entertainment and leisure, but also a platform for collaboration, innovation, and social impact.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 justify-content-md-center" >
                    <div className="section-title md" data-aos="fade-up">
                        <h2>Get to know us</h2>
                        <p>Our Misson</p>
                    </div>
                    <div className='box-sh tour' data-aos="fade-up">
                        <p className="section-text" >
                            <i className="bx bx-check"> </i>We accompany our customers in creating value through innovation by adopting revolutionary technologies like AI, Blockchain and metaverse technologies.​  <br /><br />
                            <i className="bx bx-check"> </i>Our mission is to go hand in hand with  our customers to be continually aware of
                            all the new trends with respect to emerging technologies and to empower them to identify,  experiment and implement relevant use cases for innovative and secure-by-design solutions, with concrete impact on their businesses and adding value to their final customers.   <br /><br />
                            <i className="bx bx-check"> </i>Our principal added value goes beyond having the technological expertise to the stage
                            of finding out how to empower our customers to achieve the impacts which they target according to their desired KPIs: it can be an impact on the business model, as it can be on customer satisfaction or the well-being of their employees.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhoAreWe;